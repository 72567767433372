import posthog from "posthog-js"

posthog.init(import.meta.env.REACT_APP_POSTHOG_API_KEY, {
  api_host: import.meta.env.REACT_APP_POSTHOG_API_HOST,
  ui_host: "https://eu.posthog.com",
  feature_flag_request_timeout_ms: 1000,
  bootstrap: {
    featureFlags: {},
  },
})
