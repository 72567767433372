interface UnreadNotificationPillProps {
  count: number
  direction?: "top" | "bottom"
}

export const UnreadNotificationPill: React.FC<UnreadNotificationPillProps> = ({
  count,
  direction,
}) => {
  return (
    <div className="inline-flex items-center justify-center px-5 py-3 text-xs font-bold leading-none text-white bg-red-500 rounded-full z-[999]">
      {count} unread message{count > 0 ? "s" : ""}{" "}
      {direction === "top" ? "↑" : "↓"}
    </div>
  )
}
