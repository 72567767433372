import { RefObject, useEffect, useState } from "react"
import useIsVisible from "./useIsVisible"

export default function useScrollToUnreadGroup({
  groupRefs,
  groupHeaderRefs,
  unreadCounts,
}: {
  groupRefs: RefObject<HTMLDivElement>[]
  groupHeaderRefs: RefObject<HTMLDivElement>[]
  unreadCounts: number[]
}) {
  const isGroupVisible = groupRefs.map((ref) => useIsVisible(ref))
  const isGroupHeaderVisible = groupHeaderRefs.map((ref) => useIsVisible(ref))

  const [nearestBottomUnreadGroupIndex, setNearestBottomUnreadGroupIndex] =
    useState<number>(-1)
  const [nearestTopUnreadGroupIndex, setNearestTopUnreadGroupIndex] =
    useState<number>(-1)
  const [firstTopUnreadGroupIndex, setFirstTopUnreadGroupIndex] = useState(-1)

  const [showBottomScrollIndicator, setShowBottomScrollIndicator] =
    useState(false)
  const [showTopScrollIndicator, setTopScrollIndicator] = useState(false)

  useEffect(() => {
    let shouldShowBotoomScrollIndicator = false
    for (let i = 0; i < groupRefs.length - 1; i++) {
      if (
        isGroupVisible[i] &&
        !isGroupHeaderVisible[i + 1] &&
        unreadCounts[i + 1] > 0
      ) {
        if (nearestBottomUnreadGroupIndex !== i + 1) {
          setNearestBottomUnreadGroupIndex(i + 1)
        }
        shouldShowBotoomScrollIndicator = true
        break
      }
    }

    if (shouldShowBotoomScrollIndicator !== showBottomScrollIndicator) {
      setShowBottomScrollIndicator(shouldShowBotoomScrollIndicator)
    }

    let shouldShowTopScrollIndicator = false
    let nearestIndex = -1

    for (let i = groupRefs.length - 1; i > 0; i--) {
      if (
        isGroupVisible[i] &&
        !isGroupHeaderVisible[i - 1] &&
        unreadCounts[i - 1] > 0
      ) {
        shouldShowTopScrollIndicator = true
        nearestIndex = i - 1
        break
      }
    }
    if (nearestIndex !== nearestTopUnreadGroupIndex) {
      setNearestTopUnreadGroupIndex(nearestIndex)
    }

    let firstIndex = unreadCounts.findIndex((count) => count > 0)
    if (firstIndex !== firstTopUnreadGroupIndex) {
      setFirstTopUnreadGroupIndex(firstIndex)
    }
    if (shouldShowTopScrollIndicator !== showTopScrollIndicator) {
      setTopScrollIndicator(shouldShowTopScrollIndicator)
    }
  }, [unreadCounts, isGroupVisible])

  return {
    nearestBottomUnreadGroupIndex,
    firstTopUnreadGroupIndex,
    nearestTopUnreadGroupIndex,
    showBottomScrollIndicator,
    showTopScrollIndicator,
  }
}
