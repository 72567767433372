import { Client, xml } from "@xmpp/client"
import { Element } from "@xmpp/xml"
import iqMeasure from "../../instrumentation/iqMeasure"

export type ItemsItem = {
  jid: string
  name?: string
}

type InfoIdentity = {
  category: string
  type: string
  name: string | null
}

type InfoFeature = {
  var: string
}

type InfoNode = {
  jid: string
  identities: InfoIdentity[]
  features: InfoFeature[]
}

export const iqInfo = async (xmpp: Client, jid?: string): Promise<InfoNode> => {
  const { iqCaller } = xmpp
  const measuredIqCaller = iqMeasure(iqCaller, "disco_info")
  const toJid = jid || (xmpp.jid?.domain as string)

  const response = await measuredIqCaller.request(
    xml(
      "iq",
      {
        type: "get",
        from: xmpp.jid?.toString(),
        to: toJid,
      },
      xml("query", "http://jabber.org/protocol/disco#info"),
    ),
  )
  const children = response.getChild("query")?.children as Element[]
  const identities: InfoIdentity[] = []
  const features: InfoFeature[] = []
  children.forEach((child) => {
    if (child.name === "identity") {
      identities.push({
        category: child.attrs.category,
        type: child.attrs.type,
        name: child.attrs.name,
      })
    } else if (child.name === "feature") {
      features.push({ var: child.attrs.var })
    }
  })
  return { jid: toJid, identities: identities, features: features }
}

export const iqItems = async (
  xmpp: Client,
  jid?: string,
): Promise<ItemsItem[]> => {
  const { iqCaller } = xmpp
  const measuredIqCaller = iqMeasure(iqCaller, "disco_items")
  const toJid = jid || xmpp.jid?.domain

  const response = await measuredIqCaller.request(
    xml(
      "iq",
      {
        type: "get",
        from: xmpp.jid?.toString(),
        to: toJid,
      },
      xml("query", "http://jabber.org/protocol/disco#items"),
    ),
  )

  const items = response.getChild("query")?.getChildren("item") as Element[]
  return items.map((item) => {
    const groups = item.getChildren("group").map((e) => e.text())
    return { jid: item.attrs.jid, name: item.attrs.name, groups }
  })
}

export const isMucInfo = (item: InfoNode): boolean => {
  if (item.jid.startsWith("whatsapp")) return false

  return item.identities.some(
    (identity) =>
      identity.type === "text" && identity.category === "conference",
  )
}
