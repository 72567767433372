import { RefObject, useEffect, useMemo, useState } from "react"

export default function useIsVisible(ref: RefObject<HTMLElement>) {
  const [isVisible, setIsVisible] = useState(false)

  const observer = useMemo(() => {
    if (typeof IntersectionObserver !== "undefined") {
      return new IntersectionObserver(([entry]) =>
        setIsVisible(entry.isIntersecting),
      )
    }
  }, [ref])

  useEffect(() => {
    if (!ref.current || !observer?.observe) return
    observer.observe(ref.current)
    return () => observer.disconnect()
  })

  return isVisible
}
