import React, { useCallback, useContext, useMemo } from "react"
import css from "./ProfileInfo.module.css"
import { Button, H3, Switch } from "@blueprintjs/core"
import { useAppSelector } from "../reducers/hooks"
import { selectSettings } from "../reducers/settingsSlice"
import SettingsApi from "../api/settingsApi"
import { XmppContext } from "../stream/xmppClient"
import { findProfile } from "../reducers/profilesSliceSelectors"
import AvatarImage, { participantPhotoToSrc } from "./AvatarImage"
import { captureError } from "../ErrorHandlers"
import { selectWhatsappRosterItem } from "../reducers/rosterSelectors"
import ClipboardWriter from "../lib/clipboardWriter"
import JID from "@xmpp/jid"

type ProfileInfoProp = {
  onClose: () => void
}

const ProfileInfo = ({ onClose }: ProfileInfoProp) => {
  const { client, myJid } = useContext(XmppContext)
  const settings = useAppSelector(selectSettings)

  const profile = useAppSelector(findProfile(myJid.bare().toString()))
  const whatsappRosterItem = useAppSelector(selectWhatsappRosterItem)
  const whatsappNumber = useMemo(
    () =>
      whatsappRosterItem && JID.parse(whatsappRosterItem?.jid).local.toString(),
    [whatsappRosterItem],
  )

  const onWhatsappNumberCopy = useCallback(() => {
    ClipboardWriter.executeAndWriteResult(async () => whatsappNumber)
    window.appToaster.show({
      message: "WhatsApp number copied",
      intent: "success",
    })
  }, [whatsappNumber])

  const toggleNotificationsClick = async () => {
    try {
      await SettingsApi.setMobileNotificationsEnabled(
        client,
        !settings.mobileNotificationsEnabled,
      )
    } catch (error) {
      captureError(error, {
        origin: "UserAction",
        extra: { message: "changeNotificationSettings" },
      })
    }
  }

  const toggleShowNicknameClick = async () => {
    try {
      await SettingsApi.setShowNicknameInWhatsapp(
        client,
        !settings.showNicknameInWhatsapp,
      )
    } catch (error) {
      captureError(error, {
        origin: "UserAction",
        extra: { message: "changeNicknameSettings" },
      })
    }
  }

  return (
    <div className={css.chatInfo}>
      <div className={css.header}>
        <Button minimal icon="cross" onClick={onClose} />
      </div>
      <div className={css.body}>
        {profile && (
          <>
            <AvatarImage
              name={profile.name}
              src={participantPhotoToSrc(profile.photo)}
              size="xlarge"
            />
            <H3>{profile.name}</H3>
          </>
        )}

        {settings.pushSubscriptionPresent && (
          <Switch
            label="Mobile notifications"
            innerLabelChecked="on"
            innerLabel="off"
            onChange={toggleNotificationsClick}
            checked={settings.mobileNotificationsEnabled}
            alignIndicator="right"
            className={css.noMargin}
          />
        )}

        <Switch
          label="Show my name to drivers"
          innerLabelChecked="on"
          innerLabel="off"
          onChange={toggleShowNicknameClick}
          checked={settings.showNicknameInWhatsapp}
          alignIndicator="right"
          className={css.noMargin}
        />

        {whatsappNumber && (
          <div className="flex flex-row w-full justify-between items-center">
            <span>WhatsApp number</span>
            <div className="flex flex-row gap-x-2 items-center">
              <span>{whatsappNumber}</span>
              <Button onClick={onWhatsappNumberCopy}>Copy</Button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProfileInfo
