import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { Button, Classes, Icon, Popover } from "@blueprintjs/core"
import SearchBar from "./SearchBar"
import ChatCard from "./ChatCard"
import { addBookmark } from "../api/xeps/PepNativeBookmarks"
import classNames from "classnames"
import { XmppContext } from "../stream/xmppClient"
import XmppGroupChatApi from "../api/xmppGroupChatApi"
import utilsCss from "./utilsCss.module.css"
import css from "./JoinChatPopover.module.css"
import { useAppStore, useAppSelector } from "../reducers/hooks"
import { joinChat } from "../reducers/chatsSliceThunks"
import {
  ChatSimplistic,
  selectSimpleChats,
} from "../reducers/chatsSliceSelectors"
import ChatHelpers from "../lib/chatHelpers"
import { selectSeenChatJids } from "../reducers/seenChatsSelectors"
import { addToSeenChats } from "../reducers/seenChatsSlice"
import useSetIntervalInForeground from "../hooks/useSetIntervalInForeground"

type JoinChatPopoverProps = {
  onActiveChatChange: (chatJid: string) => void
}

const JoinChatPopover = ({ onActiveChatChange }: JoinChatPopoverProps) => {
  const { client } = useContext(XmppContext)
  const store = useAppStore()
  const chats = useAppSelector(selectSimpleChats)
  const seenJids: string[] = useAppSelector(selectSeenChatJids)

  const [searchValue, setSearchValue] = useState<string>("")
  const {
    chats: allChats,
    refetchChats,
    hasFetched: chatsDidFetch,
  } = useGetAllChats()

  const chatOptions = useMemo(() => {
    const all = allChats.filter(
      (chat) => !chats.some(({ jid }) => jid === chat.jid),
    )
    const filtered = all.filter((chat) =>
      chat.name.toLowerCase().includes(searchValue.toLowerCase()),
    )

    return { all, filtered }
  }, [chats, allChats, searchValue])

  const onChatSelect = useCallback(
    async (chat: ChatSimplistic) => {
      setSearchValue("")
      await addBookmark(client, chat.jid, chat.name)
      store.dispatch(joinChat(client, store, chat))
      onActiveChatChange(chat.jid)
    },
    [client, store, onActiveChatChange],
  )

  const shouldDisplayJoinChat = !chatsDidFetch || chatOptions.all.length > 0
  const shouldDisplaySearchBar = chatOptions.all.length > 5
  const newChats = useMemo(() => {
    if (!chatsDidFetch) return []
    if (!seenJids || seenJids.length === 0) return []

    return chatOptions.all.filter((chat) => !seenJids.includes(chat.jid))
  }, [seenJids, chatsDidFetch, chatOptions.all])

  const newChatsFirstSortFn = useCallback(
    (a: ChatSimplistic, b: ChatSimplistic) => {
      if (newChats.includes(a)) return -1
      if (newChats.includes(b)) return 1
      return a.name.localeCompare(b.name)
    },
    [newChats],
  )

  const markAllChatsAsSeen = useCallback(() => {
    store.dispatch(addToSeenChats(newChats.map((chat) => chat.jid)))
  }, [store, chatOptions.all])

  const ChatOptions = () => {
    if (!chatsDidFetch) {
      return (
        <div className={utilsCss.noResults}>
          Could not get your chats, try again
        </div>
      )
    }

    if (chatOptions.filtered.length === 0) {
      return <div className={utilsCss.noResults}>No Results</div>
    }

    return (
      <>
        {chatOptions.filtered.sort(newChatsFirstSortFn).map((chat) => (
          <ChatCard
            compact
            hasNewIndicator={newChats.includes(chat)}
            key={chat.jid}
            chatJid={chat.jid}
            chatName={chat.name}
            chatActive={false}
            chatColor={ChatHelpers.getChatColor(chat)}
            className={Classes.POPOVER_DISMISS}
            onClick={() => onChatSelect(chat)}
          />
        ))}
      </>
    )
  }

  return (
    <Popover
      interactionKind="click"
      onClose={() => markAllChatsAsSeen()}
      placement="bottom"
      content={
        <div className={css.joinChatContainer}>
          {shouldDisplaySearchBar && (
            <div className={css.joinChatSearchBar}>
              <SearchBar
                value={searchValue}
                onValueChange={setSearchValue}
                inPopover
              />
            </div>
          )}
          <div className={css.joinChatList}>
            <ChatOptions />
          </div>
        </div>
      }
      renderTarget={({ className, onClick, isOpen, ...targetProps }) => (
        <div className="relative">
          <Button
            icon={<Icon icon="annotation" size={18} />}
            minimal
            style={{ display: shouldDisplayJoinChat ? "flex" : "none" }}
            className={classNames(css.addChatButton, className)}
            onClick={(e) => {
              refetchChats()
              onClick?.(e)
            }}
            {...targetProps}
          />
          {newChats.length > 0 && (
            <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
              •
            </span>
          )}
        </div>
      )}
    />
  )
}

const useGetAllChats = () => {
  const [chats, setChats] = useState<ChatSimplistic[]>([])
  const [hasFetched, setHasFetched] = useState<boolean>(false)
  const { client } = useContext(XmppContext)

  const fetchChats = useCallback(() => {
    if (!client.jid) return

    XmppGroupChatApi.fetchRooms(client).then((chats) => {
      // We assume that any successful fetch causes this to render.
      // Upside is that we will show this interface once anything fetched.
      // Downside is that on further failures we won't show updated rooms.
      setHasFetched(true)
      setChats(chats)
    })
  }, [client])

  useEffect(() => fetchChats(), [fetchChats, client.jid])
  useSetIntervalInForeground({
    fn: fetchChats,
    interval: 60000,
    deps: [fetchChats, client.jid],
  })
  return { chats, refetchChats: fetchChats, hasFetched }
}

export default JoinChatPopover
