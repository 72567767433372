import React, { useCallback, useContext, useState, useMemo } from "react"

import AvatarImage from "./AvatarImage"
import { Chat } from "../reducers/chatsSlice"
import { useAppDispatch, useAppSelector, useAppStore } from "../reducers/hooks"
import { Button, Icon, OverlaysProvider, Tooltip } from "@blueprintjs/core"
import useChatInfo from "../hooks/useChatInfo"
import { selectChatSubject } from "../reducers/subjectsSlice"
import css from "./ChatHeader.module.css"
import { isMobile } from "../utils/detectDevice"
import utilsCss from "./utilsCss.module.css"
import classNames from "classnames"
import { XmppContext } from "../stream/xmppClient"
import { removeBookmark } from "../api/xeps/PepNativeBookmarks"
import { selectRoomChats } from "../reducers/chatsSliceSelectors"
import { shallowEqual } from "react-redux"
import { leaveChat } from "../reducers/chatsSliceThunks"
import ChatHelpers from "../lib/chatHelpers"
import SearchPopover from "./search/SearchPopover"
import {
  hideChatSearch,
  isChatSearchVisible,
  showChatSearch,
} from "../reducers/overlaysSlice"
import { mobileRoutes, setMobileRoute } from "../routes/routes"
import { AddEditUserModal } from "./userManagement/AddEditUserModal"

type ChatHeaderProp = {
  chat: Chat
  onReturnToChatLists: () => void
  onOpenChatInfo: () => void
}

const ChatHeader = ({
  chat,
  onReturnToChatLists,
  onOpenChatInfo,
}: ChatHeaderProp) => {
  const { client } = useContext(XmppContext)
  const store = useAppStore()
  const dispatch = useAppDispatch()
  const chats = useAppSelector(selectRoomChats, shallowEqual)

  const [driverParticipant, chatTitle, photoSrc, additionalInfo] =
    useChatInfo(chat)
  const subject = useAppSelector(selectChatSubject(chat.jid))
  const [copySuccessAnimate, setCopySuccessAnimate] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  const onSubjectClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  const copySubject = (e: React.MouseEvent) => {
    e.stopPropagation()
    navigator.clipboard.writeText(subject?.text || "")

    setCopySuccessAnimate(true)
    setTimeout(() => {
      setCopySuccessAnimate(false)
    }, 700)
  }

  const buttonIcon = () => {
    if (!copySuccessAnimate) {
      return <Icon icon="duplicate" size={12} />
    }

    return <Icon icon="tick" size={12} />
  }

  const onExitChat = async (e: React.MouseEvent) => {
    e.stopPropagation()
    onReturnToChatLists()
    await removeBookmark(client, chat.jid)
    store.dispatch(leaveChat(client, store, chat))
  }

  const handleShowSearch = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      // @ts-ignore
      e.currentTarget?.blur?.()
      dispatch(showChatSearch())
      if (isMobile) {
        setMobileRoute(mobileRoutes.SEARCH)
      }
    },
    [dispatch],
  )

  const onBackClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    onReturnToChatLists()
  }

  const handleCloseSearch = useCallback(() => {
    if (isMobile) {
      setMobileRoute(mobileRoutes.CHAT)
    }
    dispatch(hideChatSearch())
  }, [dispatch])

  const handleEditExternalContact = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      setIsEditModalOpen(true)
    },
    [setIsEditModalOpen],
  )

  const handleCloseEditExternalContact = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      setIsEditModalOpen(false)
    },
    [setIsEditModalOpen],
  )

  const searchVisible = useAppSelector(isChatSearchVisible)

  const isExternalContact = ChatHelpers.isChatWithExternalContact(chat)
  const [firstName, lastName] = useMemo(() => {
    const [first, ...rest] = chat.name.split(" ")
    return [first, rest.join(" ")]
  }, [chat.name])
  const externalContactPhone = isExternalContact
    ? ChatHelpers.getExternalContactPhone(chat)
    : undefined
  return (
    <>
      <AddEditUserModal
        isOpen={isEditModalOpen}
        firstName={firstName}
        lastName={lastName}
        phoneNumber={externalContactPhone}
        onClose={handleCloseEditExternalContact}
      />
      <div
        className={css.header}
        onClick={isMobile || !chat.room ? undefined : onOpenChatInfo}
      >
        <Button
          data-testid="exit-chat-button"
          large
          minimal
          icon="arrow-left"
          onClick={onBackClick}
          className={css.backToChatlist}
          text={undefined}
        />
        <AvatarImage
          color={ChatHelpers.getChatColor(chat)}
          name={chatTitle}
          src={photoSrc}
        />
        <div
          style={{ display: "flex", gap: 12 }}
          onClick={
            isExternalContact && !isMobile
              ? handleEditExternalContact
              : undefined
          }
        >
          <div className={css.nameAndSubject}>
            <div className={css.name}>
              <span data-testid="chat-title">
                <b className={css.title}>{chatTitle}</b>
              </span>
              <span
                className={classNames(utilsCss.dimmedText, css.additionalInfo)}
              >
                {isExternalContact ? externalContactPhone : additionalInfo}
              </span>
            </div>
            {subject && subject.text && (
              <div className={css.subjectContainer}>
                <Button
                  className={css.copyButton}
                  icon={buttonIcon()}
                  minimal
                  small
                  onClick={copySubject}
                  tabIndex={-1}
                  intent={copySuccessAnimate ? "success" : "none"}
                />
                <div
                  className={classNames(css.subject, utilsCss.dimmedText)}
                  onClick={onSubjectClick}
                >
                  {subject?.text || ""}
                </div>
              </div>
            )}
          </div>
          {isMobile && driverParticipant?.phone && (
            <div style={{ display: "inline", marginLeft: "auto" }}>
              <a href={`tel:${driverParticipant.phone}`} target="_parent">
                <Icon icon="phone" />
              </a>
            </div>
          )}
          {isMobile && isExternalContact && (
            <div style={{ display: "inline", marginLeft: "auto" }}>
              <a href={`tel:${externalContactPhone}`} target="_parent">
                <Icon icon="phone" />
              </a>
            </div>
          )}
        </div>
        <div className={css.chatHeaderToolbar}>
          {isExternalContact && (
            <Button
              minimal
              icon={<Icon icon="edit" size={18} />}
              onClick={handleEditExternalContact}
            />
          )}
          <OverlaysProvider>
            <Tooltip content="Search in messages" position="left">
              <Button
                data-testid="chat-search-button"
                minimal
                icon={<Icon icon="search" size={18} />}
                onClick={handleShowSearch}
              />
            </Tooltip>
            <SearchPopover
              isOpen={searchVisible}
              onClose={handleCloseSearch}
              chatJid={chat.jid}
              key={chat.jid}
            />
          </OverlaysProvider>
          {chats.length > 1 && ChatHelpers.canUserLeaveChat(chat) && (
            <Tooltip content="Leave chat" position="left">
              <Button
                minimal
                icon={<Icon icon="log-out" size={18} />}
                onClick={onExitChat}
              />
            </Tooltip>
          )}
        </div>
      </div>
    </>
  )
}

export default ChatHeader
