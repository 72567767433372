import { AppDispatch, AppStore, RootState } from "./store"
import ChatHelpers from "../lib/chatHelpers"
import { Client, jid, xml } from "@xmpp/client"
import { Element } from "@xmpp/xml"
import { v4 as uuid } from "uuid"
import XmppGroupChatApi from "../api/xmppGroupChatApi"
import { StanzaType } from "../lib/stanzaParser"
import MessageHelpers from "../lib/messageHelpers"
import ChatMarkersApi from "../api/chatMarkersApi"
import MarkerParser from "../lib/markerParser"
import ReactionsParser from "../lib/reactionsParser"
import { selectActiveChat, selectActiveChatIndex } from "./chatsSliceSelectors"
import XmppApi from "../api/xmppApi"
import MessageParser from "../lib/messageParser"
import XmppSimsApi from "../api/xmppSimsApi"
import { messageAuthorName } from "./profilesSliceSelectors"
import {
  AddMessageAction,
  Chat,
  Message,
  Reaction,
  upsertChat,
  addMessage,
  addBatchMessageReactions,
  addMessageCorrection,
  changeActiveChat,
  markMessage,
  removeChat,
  MessageDisplayType,
} from "./chatsSlice"
import { add as addToSendoutQueue } from "./sendoutQueueSlice"
import { selectChatReplyMessage } from "./chatRepliesSliceSelectors"
import { parse as jidParse } from "../lib/cachedJid"
import { JID } from "@xmpp/jid"
import { addToSyncQueue } from "./roomSyncStatusSlice"

// TODO remove as it is not used in production code - but requires changing syncSingleRoom tests
export const sendChatPresence = (XMPP: Client, requestHistorySince?: Date) => {
  return (_dispatch: AppDispatch, getState: () => RootState) => {
    const state: RootState = getState()
    state.chats.forEach((chat) => {
      if (chat.room && XMPP.jid) {
        XmppGroupChatApi.sendPresence(
          XMPP,
          chat.jid,
          XMPP.jid?.getLocal(),
          requestHistorySince,
        )
      }
    })
  }
}

export const sendFile = (file: File, url: string, client: Client) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const activeChat = selectActiveChat(getState())

    const message = XmppSimsApi.buildMessage(
      client,
      file,
      activeChat.jid,
      url,
      activeChat.room ? "groupchat" : "chat",
    )

    sendGroupchatStanza(client, dispatch, message)
  }
}

export const sendMessage = (
  text: string,
  client: Client,
  myJid: string,
  activeChat: Chat,
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const replyMessage = selectChatReplyMessage(activeChat)(getState())

    if (replyMessage) {
      const stanza = buildReplyGroupchatStanza(
        activeChat.jid,
        text,
        replyMessage,
      )
      sendGroupchatStanza(client, dispatch, stanza)
      return
    }

    if (activeChat.room) {
      const stanza = buildPlainGroupchatStanza(activeChat.jid, text)
      sendGroupchatStanza(client, dispatch, stanza)
      return
    }

    XmppApi.sendMessage(client, activeChat.jid, text).then((message) => {
      const parsedMessage = MessageParser.parse(message, {
        timestamp: new Date().toISOString(),
      })
      if (MessageHelpers.isToMyself(parsedMessage)) return

      dispatch(
        addMessage({
          message: parsedMessage,
          myJid,
        }),
      )
    })
  }
}

const buildPlainGroupchatStanza = (to: string, text: string): Element => {
  const messageElements = [
    xml("body", {}, text),
    xml("markable", "urn:xmpp:chat-markers:0"),
  ]
  return xml(
    "message",
    { type: "groupchat", to, from: window.myJID.toString(), id: uuid() },
    ...messageElements,
  )
}

const buildReplyGroupchatStanza = (
  to: string,
  text: string,
  replyMessage: Message,
) => {
  const replyFallback = `> ${MessageHelpers.userName(replyMessage)} wrote:\n${replyMessage.body
    .split("\n")
    .map((line) => `> ${line}`)
    .join("\n")}\n`

  const messageElements = [
    xml("body", {}, `${replyFallback}${text}`),
    xml("markable", "urn:xmpp:chat-markers:0"),
    xml("reply", {
      xmlns: "urn:xmpp:reply:0",
      to: replyMessage.from,
      id: replyMessage.id,
    }),
    xml(
      "fallback",
      { xmlns: "urn:xmpp:fallback:0", for: "urn:xmpp:reply:0" },
      xml("body", { start: 0, end: replyFallback.length }),
    ),
  ]

  return xml(
    "message",
    { type: "groupchat", to, from: window.myJID.toString(), id: uuid() },
    ...messageElements,
  )
}

const generateIncomingMessageBasedOnOutgoing = (
  message: Message | Reaction,
  myJID: JID,
): any => {
  const newMessage = { ...message }
  const toJid = jidParse(message.to)
  newMessage.from = `${toJid.bare()}/${myJID.local}`
  newMessage.to = myJID.toString()

  return newMessage
}

const parseToMessage = (stanza: Element) => {
  const message = ReactionsParser.parse(stanza)
  if (message) return message

  return MessageParser.parse(stanza, { toBeSent: true })
}

export const sendGroupchatStanza = (
  client: Client,
  dispatch: AppDispatch,
  stanza: Element,
) => {
  const originId = uuid()
  const id = stanza.attrs.id
  stanza.append(xml("origin-id", { xmlns: "urn:xmpp:sid:0", id: originId }))

  window.analytics.track("GroupChatSendClick", {
    id,
    originId,
    stanza: stanza.toString(),
  })
  const message = parseToMessage(stanza)
  const fakedIncomingMessage = generateIncomingMessageBasedOnOutgoing(
    message,
    window.myJID,
  )
  const myJid = window.myJID.bare().toString()

  if (message.displayType === MessageDisplayType.Message) {
    dispatch(
      addMessage({
        message: fakedIncomingMessage,
        myJid: myJid,
      }),
    )
  } else {
    dispatch(
      addBatchMessageReactions({
        reactions: [fakedIncomingMessage],
        myJid: myJid,
      }),
    )
  }

  dispatch(
    addToSendoutQueue({
      messageId: message.id,
      stanza: stanza.toString(),
      messageType: "groupchat",
    }),
  )
  window.persistor.flush()
  window.sendoutQueueWorker.send(message.id)
}

const showDesktopNotificationIfNeeded = (
  message: Message,
  myJid: string,
  dispatch: AppDispatch,
  state: RootState,
) => {
  if (window.Notification && window.Notification.permission === "granted") {
    const chatIndexForNotification = ChatHelpers.findMessageChatIndex(
      message,
      state.chats,
      myJid,
    )

    const currentlyActiveChatIndex = selectActiveChatIndex(state)

    if (
      chatIndexForNotification === currentlyActiveChatIndex &&
      window.document.visibilityState === "visible" &&
      window.document.hasFocus()
    )
      return

    try {
      const notification = new Notification(
        messageAuthorName(message, jid.parse(myJid))(state),
        {
          body: message.body,
          icon: `/slickshift-large.png`,
        },
      )
      notification.onclick = () => {
        window.focus()
        notification.close()
        dispatch(
          changeActiveChat({
            activeChatJid: jid.parse(message.from).bare().toString(),
          }),
        )
      }
      new Audio("/notification.wav").play().catch(() => {})
    } catch (e: any) {
      console.error(e)
    }
  }
}

export const processIncomingMessage = (
  messageAction: AddMessageAction,
  client: Client,
  stanzaType: StanzaType,
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    if (!client.jid)
      throw "Can not add a new message, jid is undefined on client instance"

    // if message is a correction
    // no need to send markers / notifications
    if (messageAction.message.replaceId) {
      dispatch(addMessageCorrection(messageAction))
      return
    }

    dispatch(addMessage(messageAction))

    if (MessageHelpers.isToMyself(messageAction.message)) return

    if (
      stanzaType === "message" &&
      MessageHelpers.isIncoming(messageAction.message, client.jid) &&
      !MessageHelpers.isToMyself(messageAction.message)
    ) {
      showDesktopNotificationIfNeeded(
        messageAction.message,
        messageAction.myJid,
        dispatch,
        getState(),
      )
      const markerToJid =
        messageAction.message.type === "chat"
          ? messageAction.message.from.toString()
          : jid.parse(messageAction.message.from).bare().toString()

      ChatMarkersApi.sendMarker(
        "received",
        messageAction.message.id,
        markerToJid,
        messageAction.message.type,
        client,
      ).then((markerStanza) => {
        if (messageAction.message.type === "chat" && client.jid) {
          const parsedMarker = MarkerParser.parse(markerStanza)

          dispatch(
            markMessage({
              marker: parsedMarker,
              myJid: client.jid?.toString(),
            }),
          )
        }
      })
    }
  }
}

export const joinChat = (
  _: Client,
  store: AppStore,
  chat: Pick<Chat, "jid" | "name" | "room">,
) => {
  return async (dispatch: AppDispatch) => {
    const chatInStore = ChatHelpers.isChatInStore(store, chat.jid)

    dispatch(upsertChat({ chat }))

    if (chatInStore) {
      return
    }

    dispatch(addToSyncQueue({ jid: chat.jid }))
  }
}

export const leaveChat = (client: Client, store: AppStore, chat: Chat) => {
  return async (dispatch: AppDispatch) => {
    if (!ChatHelpers.isChatInStore(store, chat.jid)) return

    dispatch(removeChat({ jid: chat.jid }))
    XmppGroupChatApi.sendRoomExitPresence(
      client,
      chat.jid,
      client.jid!.getLocal(),
    )
  }
}

export const markMessageDisplayed = (client: Client, message: Message) => {
  return async (dispatch: AppDispatch) => {
    const myJid = client.jid?.toString()
    if (!myJid) return

    ChatMarkersApi.sendMarker(
      "displayed",
      message.id,
      message.type === "groupchat"
        ? jid.parse(message.from)?.bare().toString()
        : message.from,
      message.type,
      client,
    ).then((marker) => {
      const sendStatus = message.type === "chat" ? "sent" : "sending"
      const parsedMarker = MarkerParser.parse(marker, { sendStatus })

      dispatch(
        markMessage({
          marker: parsedMarker,
          myJid: myJid.toString(),
        }),
      )
    })
  }
}

export const markAllChatsAsRead = (client: Client) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state: RootState = getState()

    const myJid = client.jid?.toString()

    if (!myJid) return

    state.chats.forEach((chat) => {
      if (ChatHelpers.unreadMessagesCount(chat, myJid) > 0) {
        const message = chat.messages.at(-1)
        if (message) {
          dispatch(markMessageDisplayed(client, message))
        }
      }
    })
  }
}
