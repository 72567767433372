/**
 * This player adds support for ogg files in Safari, which are not supported by default.
 *
 * NOTE There is a know issue that audio does not play if the phone is in the silent mode: https://github.com/bvibber/ogv.js/issues/600
 * However, the workaround is a bit hacky (i.e. play silent audio), so for now we haven't implemented it
 */

import { useCallback, useState, useEffect } from "react"
import { Icon } from "@blueprintjs/core"
import css from "./AudioPlayer.module.css"

type AudioPlayerProps = {
  src: string
}

const AudioPlayer = ({ src }: AudioPlayerProps) => {
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [playing, setPlaying] = useState(false)
  const [ogv, setOgv] = useState<any>(null)
  const [player, setPlayer] = useState<any>(null)

  useEffect(() => {
    const loadOgv = async () => {
      const ogvModule = await import("ogv")
      ogvModule.OGVLoader.base = "ogv/dist"
      setOgv(ogvModule)
    }
    loadOgv()
  }, [])

  const formatTime = (seconds: number) => {
    const min: number = Math.floor(seconds / 60)
    const sec: number = seconds - min * 60

    const padLeft = (number: number, pad: string, length: number) => {
      return (new Array(length + 1).join(pad) + number).slice(-length)
    }

    return padLeft(min, "0", 1) + ":" + padLeft(sec, "0", 2)
  }

  const createPlayer = useCallback(() => {
    if (!ogv) return null
    const player = new ogv.OGVPlayer()
    player.src = src
    player.addEventListener("loadedmetadata", function () {
      setDuration(Math.floor(player.duration))
    })
    player.addEventListener("timeupdate", function () {
      setCurrentTime(Math.floor(player.currentTime))
    })
    player.addEventListener("play", function () {
      setPlaying(true)
    })
    player.addEventListener("pause", function () {
      setPlaying(false)
    })
    player.addEventListener("ended", function () {
      setCurrentTime(Math.floor(player.duration))
    })
    return player
  }, [src, ogv])

  useEffect(() => {
    if (ogv) {
      const newPlayer = createPlayer()
      setPlayer(newPlayer)
    }
  }, [ogv, createPlayer])

  const toggle = () => {
    if (!player) return

    if (player.paused) {
      player.play()
    } else {
      player.pause()
      player.currentTime = 0
    }
  }

  return (
    <div className={css.player}>
      <span className={css.controlsContainer}>
        <Icon icon={playing ? "stop" : "play"} size={16} onClick={toggle} />
      </span>
      <span>
        {formatTime(currentTime)} / {formatTime(duration)}
      </span>
      <span>Voice Message</span>
    </div>
  )
}

export default AudioPlayer
