export const isVersionAtLeast = (
  currentVersion?: string,
  requiredVersion?: string,
): boolean => {
  if (!currentVersion || !requiredVersion) return false
  // Split version strings into components
  const current = currentVersion.split(".").map(Number)
  const required = requiredVersion.split(".").map(Number)

  // Compare each component in sequence
  for (let i = 0; i < Math.max(current.length, required.length); i++) {
    // Use 0 if component is not specified
    const currentPart = current[i] || 0
    const requiredPart = required[i] || 0

    if (currentPart > requiredPart) return true
    if (currentPart < requiredPart) return false
  }

  // Versions are exactly equal
  return true
}
