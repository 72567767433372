import * as reactDeviceDetect from "react-device-detect"

// As in Cypress we are not able to simulate mobile devices, so we need to
// override the device detection methods with our own implementation

const isMobile =
  window.Cypress?.reactDeviceDetect?.isMobile ?? reactDeviceDetect.isMobile

const isAndroid =
  window.Cypress?.reactDeviceDetect?.isAndroid ?? reactDeviceDetect.isAndroid

const isIOS =
  window.Cypress?.reactDeviceDetect?.isIOS ?? reactDeviceDetect.isIOS

const isWindows =
  window.Cypress?.reactDeviceDetect?.isWindows ?? reactDeviceDetect.isWindows

const isMacOs =
  window.Cypress?.reactDeviceDetect?.isMacOs ?? reactDeviceDetect.isMacOs

// Re-export everything from react-device-detect
export { isSafari, browserVersion } from "react-device-detect"
export { isMobile, isAndroid, isIOS, isWindows, isMacOs }
