export class NativePartNotifier {
  static setDarkModeForNativePart = (isDarkMode: boolean) => {
    if (!window.ReactNativeWebView) {
      return
    }

    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: "DarkModeEnabled", data: { isDarkMode } }),
    )
  }
}
