import React, {
  memo,
  useMemo,
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
  RefObject,
} from "react"
import css from "./ChatList.module.css"
import SearchBar from "./SearchBar"
import ChatListGroup from "./ChatListGroup"
import { useAppSelector } from "../reducers/hooks"
import { driversBasedOnRoster } from "../reducers/rosterSelectors"
import { driverJid } from "../jidUtils"
import {
  ChatWithMessages,
  selectSimpleChats,
  selectFilteredChatsAndMessages,
} from "../reducers/chatsSliceSelectors"

import ChatHelpers from "../lib/chatHelpers"
import { XmppContext } from "../stream/xmppClient"
import { UnreadNotificationPill } from "./UnreadNotificationPill"
import useScrollToUnreadGroup from "../hooks/useScrollToUnreadGroup"

type ChatListProps = {
  onActiveChatChange: (chatJid: string) => void
}

const ChatList = ({ onActiveChatChange }: ChatListProps) => {
  const [filterValue, setFilterValue] = useState<string>("")
  const driverRosterItems = useAppSelector(driversBasedOnRoster)
  const chats = useAppSelector(selectSimpleChats)
  const chatJids = useMemo(() => chats.map((chat) => chat.jid), [chats])
  const chatsWithMessages = useAppSelector(
    selectFilteredChatsAndMessages(chatJids),
  )

  const { myJid } = useContext(XmppContext)

  const onValueChange = (value: string) => {
    setFilterValue(value)
  }

  const groupUnreadCount = useCallback(
    (jids: string[]) => {
      return chatsWithMessages
        .filter((c) => jids.some((j) => j === c.jid))
        .reduce(
          (acc, curr) =>
            acc + ChatHelpers.unreadMessagesCount(curr, myJid.toString()),
          0,
        )
    },
    [chatsWithMessages, myJid],
  )

  const filteredChats = useMemo(() => {
    if (filterValue.length === 0) return chats

    return chats.filter((chat) =>
      chat.name.toLowerCase().includes(filterValue.toLowerCase()),
    )
  }, [filterValue, chats])

  const driversChatJids = useMemo(() => {
    return chats
      .filter((chat) => {
        return driverRosterItems.some(
          (driver) => driver.jid === driverJid(chat.jid),
        )
      })
      .map((chat) => chat.jid)
  }, [chats, driverRosterItems])

  const groupChatJids = useMemo(() => {
    return chats
      .filter((chat) => !chat.jid.includes("@external."))
      .filter((chat) => {
        return !driverRosterItems.some(
          (driver) => driver.jid === driverJid(chat.jid),
        )
      })
      .map((chat) => chat.jid)
  }, [chats, driverRosterItems])

  const externalJids = useMemo(() => {
    return chats
      .filter((chat) => chat.jid.includes("@external."))
      .map((chat) => chat.jid)
  }, [chats])

  const unreadCounts = useMemo(() => {
    return [
      groupUnreadCount(groupChatJids),
      groupUnreadCount(driversChatJids),
      groupUnreadCount(externalJids),
    ]
  }, [
    chatsWithMessages,
    groupUnreadCount,
    groupChatJids,
    driversChatJids,
    externalJids,
  ])

  const groupRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ]

  const groupHeaderRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ]

  const {
    showBottomScrollIndicator,
    showTopScrollIndicator,
    nearestBottomUnreadGroupIndex,
    firstTopUnreadGroupIndex,
    nearestTopUnreadGroupIndex,
  } = useScrollToUnreadGroup({
    groupRefs,
    groupHeaderRefs,
    unreadCounts,
  })

  useEffect(() => {
    window.analytics.track("InitialRenderChatList")
  }, [])

  return (
    <>
      <div className={css.chatList}>
        {chats.length > 5 && (
          <div className={css.chatListToolbar}>
            <SearchBar value={filterValue} onValueChange={onValueChange} />
          </div>
        )}
        <div className="w-full">
          <div ref={groupRefs[0]}>
            <ChatListGroup
              name="Groups"
              chatJids={groupChatJids}
              unreadCount={unreadCounts[0]}
              onActiveChatChange={onActiveChatChange}
              filterBy={filterValue}
              ref={groupHeaderRefs[0]}
            />
          </div>
          <div ref={groupRefs[1]}>
            <ChatListGroup
              name="Drivers"
              chatJids={driversChatJids}
              onActiveChatChange={onActiveChatChange}
              unreadCount={unreadCounts[1]}
              filterBy={filterValue}
              ref={groupHeaderRefs[1]}
            />
          </div>
          <div ref={groupRefs[2]}>
            <ChatListGroup
              name="External"
              chatJids={externalJids}
              onActiveChatChange={onActiveChatChange}
              unreadCount={unreadCounts[2]}
              filterBy={filterValue}
              ref={groupHeaderRefs[2]}
            />
          </div>
          {filteredChats.length === 0 && (
            <div className={css.noResults}>No Results</div>
          )}
        </div>
      </div>
      {showTopScrollIndicator && !filterValue && (
        <div className="absolute top-32 mx-auto left-0 right-0 flex flex-row justify-center">
          <a
            onClick={() =>
              groupHeaderRefs[firstTopUnreadGroupIndex].current?.scrollIntoView(
                {
                  behavior: "smooth",
                },
              )
            }
          >
            <UnreadNotificationPill
              count={unreadCounts
                .slice(firstTopUnreadGroupIndex, nearestTopUnreadGroupIndex + 1)
                .reduce((acc, curr) => acc + curr, 0)}
              direction="top"
            />
          </a>
        </div>
      )}
      {showBottomScrollIndicator && !filterValue && (
        <div className="absolute bottom-8 mx-auto left-0 right-0 flex flex-row justify-center">
          <a
            onClick={() =>
              groupHeaderRefs[
                nearestBottomUnreadGroupIndex
              ].current?.scrollIntoView({
                behavior: "smooth",
              })
            }
          >
            <UnreadNotificationPill
              count={unreadCounts
                .slice(nearestBottomUnreadGroupIndex)
                .reduce((acc, curr) => acc + curr, 0)}
              direction="bottom"
            />
          </a>
        </div>
      )}
    </>
  )
}

ChatList.displayName = "ChatList"

export default memo(ChatList)
