import { v4 as uuidv4 } from "uuid"

export enum AnalyticsEvent {
  Startup = "Startup",
  Track = "AnalyticsTrack",
  Identify = "AnalyticsIdentify",
  SessionStarted = "SessionStarted",
}

const INITIAL_ROOM_SYNC = "InitialRoomSync"
const APP_LOADED = "AppLoaded"

export class SessionEnrichmentPlugin {
  private readonly sessionId: string
  private lastAppState: string
  private lastActiveTimestamp: number
  private hasAppStateChange: boolean
  private appLoadedTimestamp: number | null = null
  private eventCounter: number = 0
  private eventOccurrences: Map<string, number> = new Map()

  constructor() {
    this.sessionId = uuidv4()
    this.lastAppState = document.visibilityState
    this.lastActiveTimestamp = performance.now()
    this.hasAppStateChange = false
    window.addEventListener("visibilitychange", this.handleVisibilityChange)
  }

  handleVisibilityChange = () => {
    this.hasAppStateChange = true
    if (document.visibilityState === "visible") {
      this.lastActiveTimestamp = performance.now()
    }
    this.lastAppState = document.visibilityState
  }

  handleEvent = ({ payload, next }: { payload: any; next: any }) => {
    const { obj } = payload
    const { context = {}, type, event } = obj
    // Increment global event counter
    this.eventCounter++

    // Track event occurrences
    if (event) {
      const currentCount = this.eventOccurrences.get(event) || 0
      this.eventOccurrences.set(event, currentCount + 1)
    }

    if (window.ReactNativeWebView && window.ANDROID_APP_VERSION) {
      if (type === "track") {
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({
            type: AnalyticsEvent.Track,
            data: {
              event,
              properties: obj.properties,
            },
          }),
        )
      } else if (type === "identify") {
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({
            type: AnalyticsEvent.Identify,
            data: {
              userId: obj.userId,
              traits: obj.traits,
            },
          }),
        )
      }
    } else {
      try {
        context.app = context.app || {}
        context.app.version = context.app.version || window.APP_VERSION
        obj.context = context

        if (type === "track") {
          obj.properties = obj.properties ?? {}
          obj.properties.visibilityState = document.visibilityState
          obj.properties.hasAppStateChange = this.hasAppStateChange
          obj.properties.sessionId = this.sessionId
          obj.properties.eventNumber = this.eventCounter
          obj.properties.eventOccurrence = event
            ? this.eventOccurrences.get(event)
            : null
        } else if (type === "identify") {
          obj.traits = obj.traits ?? {}
          obj.traits.sessionId = this.sessionId
          obj.traits.eventNumber = this.eventCounter
          obj.traits.eventOccurrence = event
            ? this.eventOccurrences.get(event)
            : null
        }

        if (event === APP_LOADED) {
          this.appLoadedTimestamp = performance.now()
        }

        if (event === INITIAL_ROOM_SYNC) {
          const now = performance.now()
          const timeSinceAppLoaded = this.appLoadedTimestamp
            ? now - this.appLoadedTimestamp
            : null
          const timeInCurrentState = now - this.lastActiveTimestamp
          obj.properties.sessionId = this.sessionId
          obj.properties.applicationState = {
            appState: this.lastAppState,
            hasAppStateChange: this.hasAppStateChange,
            lastActiveTimestamp: this.lastActiveTimestamp,
            timeInCurrentState,
          }
          obj.properties.timing = {
            timeSinceAppLoaded,
            appLoadedAt: this.appLoadedTimestamp,
            initSyncedAt: now,
            totalSyncDuration: timeSinceAppLoaded,
          }
        }
        next(payload)
      } catch (error: any) {
        console.warn("Error in segmentMiddleware", error)
      }
    }
  }
}

if (window.ReactNativeWebView && import.meta.env.MODE === "production") {
  // An event sent to mobile that the UI was rendered.
  // Used to calculate app startup time.
  window.ReactNativeWebView.postMessage(
    JSON.stringify({ type: AnalyticsEvent.Startup }),
  )
}
