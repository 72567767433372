import { RoomSyncStatus, RoomSyncStatusJid } from "./roomSyncStatusSlice"
import { RootState } from "./store"
import { type NetworkInformation, getNetworkInformation } from "../utils"

type SyncMetadata = {
  length: number
  successLength: number
  fullSyncLength: number
  failedLength: number
  duration: number | undefined
  messagesCount: number
  markersCount: number
  reactionsCount: number
  retractionsCount: number
  totalCount: number
  networkInformationStart: NetworkInformation | undefined
  networkInformationEnd: NetworkInformation | undefined
}

export const getNextRoomSyncQueueJid =
  () =>
  (state: RoomSyncStatus): RoomSyncStatusJid | undefined => {
    return state.syncQueue[0]
  }

export const selectSyncedRoomJids = (state: RootState): string[] => {
  return state.roomSyncStatus.syncedJids
}

export const selectSyncFailedRoomJids = (state: RootState): string[] => {
  return state.roomSyncStatus.failedJids
}

export const selectIsRoomSyncFinished = (jid: string) => {
  return (state: RootState): boolean =>
    state.roomSyncStatus.syncedJids.includes(jid) ||
    state.roomSyncStatus.failedJids.includes(jid)
}

export const selectHasRoomSyncFailed = (jid: string) => {
  return (state: RootState): boolean =>
    state.roomSyncStatus.failedJids.includes(jid)
}

export const isRoomFailed =
  (jid: string) =>
  (state: RootState): boolean => {
    return state.roomSyncStatus.failedJids.some((item) => item === jid)
  }

export const isInitSyncDone = (state: RootState): boolean => {
  if (state.roomSyncStatus.preRoomSyncInProgress) return false

  return (
    state.roomSyncStatus.initSyncRoomCount <=
    state.roomSyncStatus.syncedJids.length +
      state.roomSyncStatus.failedJids.length
  )
}

export const initSyncMetadata = (state: RootState): SyncMetadata => {
  const status = state.roomSyncStatus
  const duration = status.started
    ? window.performance.now() - status.started
    : undefined
  return {
    length: status.syncedJids.length + status.failedJids.length,
    successLength: status.syncedJids.length,
    fullSyncLength: status.syncedFullJids.length,
    failedLength: status.failedJids.length,
    duration: duration,
    messagesCount: status.messagesCount,
    markersCount: status.markersCount,
    reactionsCount: status.reactionsCount,
    retractionsCount: status.retractionsCount,
    totalCount:
      status.messagesCount +
      status.markersCount +
      status.reactionsCount +
      status.retractionsCount,
    networkInformationStart: status.networkInformationStart,
    networkInformationEnd: getNetworkInformation(),
  }
}
