import { Element } from "@xmpp/xml"
import { xml } from "@xmpp/client"

// Form types as specified in XEP-0004
export type FormType = "form" | "submit" | "cancel" | "result"

// Field types as specified in XEP-0004
export type FieldType =
  | "boolean"
  | "fixed"
  | "hidden"
  | "jid-multi"
  | "jid-single"
  | "list-multi"
  | "list-single"
  | "text-multi"
  | "text-private"
  | "text-single"

export interface FormOption {
  label?: string
  value: string
}

export interface FormField {
  type?: FieldType
  var?: string
  label?: string
  desc?: string
  required?: boolean
  values: string[]
  options?: FormOption[]
}

export interface ReportedField {
  var: string
  label?: string
  type?: FieldType
}

export interface FormItem {
  fields: Record<string, FormField>
}

export interface DataForm {
  type: FormType
  title?: string
  instructions?: string[]
  fields: Record<string, FormField>
  reported?: ReportedField[]
  items?: FormItem[]
}

export class DataFormsParser {
  static parse(stanza: Element): DataForm | undefined {
    const formElement = stanza.getChild("x", "jabber:x:data")
    if (!formElement) {
      return undefined
    }

    const type = formElement.attrs.type as FormType
    if (!type) {
      throw new Error("Form must have a type attribute")
    }

    const form: DataForm = {
      type,
      fields: {},
    }

    // Parse title
    const title = formElement.getChildText("title")
    if (title) {
      form.title = title
    }

    // Parse instructions
    const instructions = formElement.getChildren("instructions")
    if (instructions.length > 0) {
      form.instructions = instructions.map((instruction) =>
        instruction.getText(),
      )
    }

    // Parse fields
    const fieldElements = formElement.getChildren("field")
    fieldElements.forEach((fieldElement) => {
      const field = this.parseField(fieldElement)
      if (field.var) {
        form.fields[field.var] = field
      }
    })

    // Parse reported fields and items for result forms
    if (type === "result") {
      const reportedElement = formElement.getChild("reported")
      if (reportedElement) {
        form.reported = reportedElement.getChildren("field").map((field) => ({
          var: field.attrs.var,
          label: field.attrs.label,
          type: field.attrs.type as FieldType,
        }))

        form.items = formElement.getChildren("item").map((item) => ({
          fields: Object.fromEntries(
            item.getChildren("field").map((field) => {
              const parsedField = this.parseField(field)
              return [field.attrs.var, parsedField]
            }),
          ),
        }))
      }
    }

    return form
  }

  private static parseField(fieldElement: Element): FormField {
    const field: FormField = {
      values: [],
    }

    // Parse basic attributes
    field.var = fieldElement.attrs.var
    field.type = (fieldElement.attrs.type || "text-single") as FieldType
    field.label = fieldElement.attrs.label

    // Parse description
    const desc = fieldElement.getChildText("desc")
    if (desc) {
      field.desc = desc
    }

    // Parse required
    field.required = fieldElement.getChild("required") !== undefined

    // Parse values
    const valueElements = fieldElement.getChildren("value")
    field.values = valueElements.map((value) => value.getText())

    // Parse options for list types
    if (field.type === "list-single" || field.type === "list-multi") {
      const optionElements = fieldElement.getChildren("option")
      field.options = optionElements.map((option) => ({
        label: option.attrs.label,
        value: option.getChildText("value") || "",
      }))
    }

    return field
  }

  static serialize(form: DataForm): Element {
    const formElement = xml("x", { xmlns: "jabber:x:data", type: form.type })

    // Add title
    if (form.title) {
      formElement.append(xml("title", {}, form.title))
    }

    // Add instructions
    if (form.instructions) {
      form.instructions.forEach((instruction) => {
        formElement.append(xml("instructions", {}, instruction))
      })
    }

    // Add fields
    Object.entries(form.fields).forEach(([varName, field]) => {
      const fieldElement = this.serializeField(varName, field)
      formElement.append(fieldElement)
    })

    // Add reported fields and items for result forms
    if (form.type === "result" && form.reported && form.items) {
      const reportedElement = xml("reported")
      form.reported.forEach((reportedField) => {
        const fieldElement = xml("field", {
          var: reportedField.var,
          ...(reportedField.label && { label: reportedField.label }),
          ...(reportedField.type && { type: reportedField.type }),
        })
        reportedElement.append(fieldElement)
      })
      formElement.append(reportedElement)

      form.items.forEach((item) => {
        const itemElement = xml("item")
        Object.entries(item.fields).forEach(([varName, field]) => {
          const fieldElement = this.serializeField(varName, field)
          itemElement.append(fieldElement)
        })
        formElement.append(itemElement)
      })
    }

    return formElement
  }

  private static serializeField(varName: string, field: FormField): Element {
    const attrs: Record<string, string> = {
      var: varName,
    }

    if (field.type) {
      attrs.type = field.type
    }

    if (field.label) {
      attrs.label = field.label
    }

    const fieldElement = xml("field", attrs)

    // Add description
    if (field.desc) {
      fieldElement.append(xml("desc", {}, field.desc))
    }

    // Add required
    if (field.required) {
      fieldElement.append(xml("required"))
    }

    // Add values
    field.values.forEach((value) => {
      fieldElement.append(xml("value", {}, value))
    })

    // Add options
    if (field.options) {
      field.options.forEach((option) => {
        const optionElement = xml(
          "option",
          option.label ? { label: option.label } : {},
        )
        optionElement.append(xml("value", {}, option.value))
        fieldElement.append(optionElement)
      })
    }

    return fieldElement
  }
}
