import {
  Dispatch,
  createListenerMiddleware,
  ListenerEffectAPI,
  ThunkDispatch,
  AnyAction,
} from "@reduxjs/toolkit"
import {
  addToSyncQueue,
  addToSynced,
  markAsFailed,
} from "../reducers/roomSyncStatusSlice"
import {
  lastSyncedAtIsFarBehind,
  syncSingleRoom,
} from "../routes/archiveLoader"
import { driverJid } from "../jidUtils"
import XmppVCardApi from "../api/xmppVCardApi"
import XmppPubSubApi from "../api/xmppPubSubApi"
import { upsertProfile } from "../reducers/profilesSlice"
import { RootState } from "../reducers/store"
import {
  sortChatsByLatestMessageTimestamp,
  Participant,
  addOrUpdateParticipant,
} from "../reducers/chatsSlice"
import { upsertLocation } from "../reducers/locationsSlice"
import { retry } from "../utils"
import RoomSyncQueue from "../lib/roomSyncQueue"
import { upsertRoute } from "../reducers/routesSlice"
import { upsertRoute2g } from "../reducers/routes2gSlice"
import { upsertActivity } from "../reducers/activitySlice"
import {
  isInitSyncDone,
  initSyncMetadata,
} from "../reducers/roomSyncStatusSelectors"

import { captureError } from "../ErrorHandlers"
import { selectLastSyncedAt } from "../reducers/chatMetaSlice"
import { addToSeenChats } from "../reducers/seenChatsSlice"

type ProcessDriver = {
  roomJid: string
  getState: () => RootState
  dispatch: Dispatch
}

const processVcards = async ({ roomJid, dispatch }: ProcessDriver) => {
  const jid = driverJid(roomJid)
  const vcard = await XmppVCardApi.fetchVCard(window.XMPP!, jid)
  if (!vcard) return

  dispatch(upsertProfile({ profile: vcard }))
  dispatch(addOrUpdateParticipant({ chatJid: roomJid, participant: vcard }))
}

const processDriver = async ({
  roomJid,
  getState,
  dispatch,
}: ProcessDriver) => {
  const state = getState()
  const chat = state.chats.find((c) => c.jid === roomJid)
  if (!chat) return

  const jid = driverJid(roomJid)
  let profile = state.profiles.find((profile) => profile.jid === jid)
  if (!profile || chat.participants.length === 0) {
    await processVcards({ roomJid, getState, dispatch })
    // After processing vCards we can search for profile once again
    profile = getState().profiles.find((profile) => profile.jid === jid)
  }
  if (profile && profile.role === "driver") {
    await subscribeDriver(jid)
    await fetchDriverDirection(dispatch, roomJid, profile)
    await fetchDriverRoute(dispatch, roomJid, profile)
    await fetchDriverActivity(dispatch, roomJid, profile)
    await fetchRoute2g(dispatch, roomJid, profile)
  }
}

const subscribeDriver = async (jid: string) => {
  const nodes = [
    "http://jabber.org/protocol/geoloc",
    "route",
    "http://jabber.org/protocol/activity",
    "urn:slickshift:route:0",
  ]
  for (const node of nodes) {
    try {
      await XmppPubSubApi.subscribe(window.XMPP!, jid, node)
    } catch (error: any) {
      if (error.condition !== "item-not-found") {
        console.warn(`Error subscribing to ${jid} ${node}`, error)
      }
    }
  }
}

const fetchDriverDirection = async (
  dispatch: Dispatch,
  chatJid: string,
  participant: Participant,
) => {
  try {
    const direction = await XmppPubSubApi.fetchDirection(
      window.XMPP!,
      participant.jid,
    )
    if (!direction) return

    if (direction.location) {
      dispatch(
        upsertLocation({
          jid: participant.jid,
          itemId: "current",
          value: direction.location,
        }),
      )
    }
    dispatch(
      upsertLocation({
        jid: participant.jid,
        itemId: "next_destination",
        value: direction.nextDestination,
      }),
    )
  } catch (error: any) {
    if (error.condition !== "item-not-found") {
      throw error
    }
  }
}

const fetchDriverRoute = async (
  dispatch: Dispatch,
  chatJid: string,
  participant: Participant,
) => {
  try {
    const route = await XmppPubSubApi.fetchRoute(window.XMPP!, participant.jid)
    if (!route) return
    dispatch(upsertRoute({ route }))
  } catch (error: any) {
    if (error.condition !== "item-not-found") {
      throw error
    }
  }
}

const fetchRoute2g = async (
  dispatch: Dispatch,
  chatJid: string,
  participant: Participant,
) => {
  try {
    const route = await XmppPubSubApi.fetchRoute2g(
      window.XMPP!,
      participant.jid,
    )
    if (!route) return
    dispatch(upsertRoute2g({ route }))
  } catch (error: any) {
    if (error.condition !== "item-not-found") {
      throw error
    }
  }
}

const fetchDriverActivity = async (
  dispatch: Dispatch,
  chatJid: string,
  participant: Participant,
) => {
  try {
    const activity = await XmppPubSubApi.fetchActivity(
      window.XMPP!,
      participant.jid,
    )
    if (!activity) return
    dispatch(upsertActivity({ activity }))
  } catch (error: any) {
    if (error.condition !== "item-not-found") {
      throw error
    }
  }
}

let persistAfterSyncTimeout: number | undefined

const persistDebounced = () => {
  if (persistAfterSyncTimeout) {
    window.clearTimeout(persistAfterSyncTimeout)
  }

  persistAfterSyncTimeout = window.setTimeout(() => {
    console.log("Persisting after room sync")
    window.persistor.flush()
  }, 2000)
}

let sortRoomsTimeout: number | undefined

const sortRoomsDebounced = (dispatch: Dispatch) => {
  if (sortRoomsTimeout) {
    window.clearTimeout(sortRoomsTimeout)
  }

  sortRoomsTimeout = window.setTimeout(() => {
    console.log("Sorting rooms")
    dispatch(sortChatsByLatestMessageTimestamp())
  }, 2000)
}

const sortRoomsImmediatelyIfSyncDone = (
  listenerApi: ListenerEffectAPI<
    unknown,
    ThunkDispatch<unknown, unknown, AnyAction>,
    unknown
  >,
) => {
  const state = listenerApi.getState() as RootState
  if (isInitSyncDone(state)) {
    listenerApi.dispatch(sortChatsByLatestMessageTimestamp())
  }
}

const addAllRoomsToSeenIfSyncDone = (
  listenerApi: ListenerEffectAPI<
    unknown,
    ThunkDispatch<unknown, unknown, AnyAction>,
    unknown
  >,
) => {
  const state = listenerApi.getState() as RootState
  if (isInitSyncDone(state)) {
    listenerApi.dispatch(addToSeenChats(state.chats.map((c) => c.jid)))
  }
}
const ROOM_SYNC_RETRIES = 3
const JOIN_ROOM_TIMEOUT = 2000

const reportFullSync = (
  listenerApi: ListenerEffectAPI<
    unknown,
    ThunkDispatch<unknown, unknown, AnyAction>,
    unknown
  >,
) => {
  const state = listenerApi.getState() as RootState
  if (isInitSyncDone(state)) {
    const meta = initSyncMetadata(state)
    window.analytics.track("InitialRoomSync", meta)
    console.log("InitialRoomSync", meta)
  }
}

const processSubscriptions = async (
  action: { payload: { jid: string } },
  listenerApi: ListenerEffectAPI<
    unknown,
    ThunkDispatch<unknown, unknown, AnyAction>,
    unknown
  >,
) => {
  await retry(
    processDriver,
    [
      {
        roomJid: action.payload.jid,
        getState: listenerApi.getState as () => RootState,
        dispatch: listenerApi.dispatch,
      },
    ],
    { maxAttempts: ROOM_SYNC_RETRIES, backoff: true },
  )

  RoomSyncQueue.enqueue(() =>
    processVcards({
      roomJid: action.payload.jid,
      getState: listenerApi.getState as () => RootState,
      dispatch: listenerApi.dispatch,
    }).catch((error) => {
      captureError(error, {
        origin: "InitialLoad",
        extra: { message: "processVcards", jid: action.payload.jid },
      })
    }),
  )
}

const processRoomSyncEffect = async (
  action: { payload: { jid: string } },
  listenerApi: ListenerEffectAPI<
    unknown,
    ThunkDispatch<unknown, unknown, AnyAction>,
    unknown
  >,
) => {
  const syncStart = new Date()
  try {
    const isFullSyncing = lastSyncedAtIsFarBehind(
      selectLastSyncedAt(action.payload.jid)(
        listenerApi.getState() as RootState,
      ),
      listenerApi.getState() as RootState,
    )

    await retry(
      syncSingleRoom,
      [
        action.payload.jid,
        window.XMPP!,
        listenerApi.getState() as RootState,
        listenerApi.dispatch,
        { modern: true },
      ],
      { maxAttempts: ROOM_SYNC_RETRIES, backoff: true },
    )
    persistDebounced()
    sortRoomsDebounced(listenerApi.dispatch)
    await retry(
      window.mucPlugin.joinRoom,
      [action.payload.jid, window.myJID.local, syncStart, JOIN_ROOM_TIMEOUT],
      { maxAttempts: ROOM_SYNC_RETRIES, backoff: true },
    )
    RoomSyncQueue.enqueue(() =>
      processSubscriptions(action, listenerApi).catch((e) => {
        captureError(e, {
          origin: "InitialLoad",
          extra: { message: "processSubscriptions", jid: action.payload.jid },
        })
      }),
    )
    listenerApi.dispatch(
      addToSynced({ jid: action.payload.jid, wasFullSynced: isFullSyncing }),
    )
  } catch (e) {
    console.error("Error syncing room", e)
    listenerApi.dispatch(markAsFailed({ jid: action.payload.jid }))
  } finally {
    reportFullSync(listenerApi)
    sortRoomsImmediatelyIfSyncDone(listenerApi)
    addAllRoomsToSeenIfSyncDone(listenerApi)
  }
}

const roomSyncListener = createListenerMiddleware()
roomSyncListener.startListening({
  actionCreator: addToSyncQueue,
  effect: async (action, listenerApi) => {
    RoomSyncQueue.enqueue(() => processRoomSyncEffect(action, listenerApi))
  },
})

export default roomSyncListener
