import React, { useCallback, useContext, useEffect, useState } from "react"
import { XmppContext } from "../stream/xmppClient"
import classNames from "classnames"
import css from "./ChatListHeader.module.css"
import JoinChatPopover from "./JoinChatPopover"
import {
  Button,
  Divider,
  Icon,
  Menu,
  MenuItem,
  OverlaysProvider,
  Popover,
  Tooltip,
} from "@blueprintjs/core"
import SearchPopover from "./search/SearchPopover"
import RoomLoading from "./RoomLoading"
import { useAppDispatch, useAppSelector } from "../reducers/hooks"
import {
  hideGlobalSearch,
  isGlobalSearchVisible,
  showGlobalSearch,
} from "../reducers/overlaysSlice"
import { isMobile } from "../utils/detectDevice"
import { mobileRoutes, setMobileRoute } from "../routes/routes"
import { markAllChatsAsRead } from "../reducers/chatsSliceThunks"
import { getWhatsappStatus } from "../reducers/rosterThunks"
import { captureError } from "../ErrorHandlers"
import WhatsappAccountStatusDialog from "./WhatsappAccountStatusDialog"
import Cookies from "universal-cookie"
import { isAndroid } from "react-device-detect"
import { isVersionAtLeast } from "../utils/mobileVersion"
import AddUserButton from "./userManagement/AddUserButton"
import posthog from "posthog-js"
import { AddEditDriverModal } from "./driverManagement/AddEditDriverModal"
import { useDarkMode } from "../contexts/DarkModeContext"

type ChatListHeaderProps = {
  onOpenChatInfo: () => void
  onActiveChatChange: (chatJid: string) => void
}

const ChatListHeader = ({
  onOpenChatInfo,
  onActiveChatChange,
}: ChatListHeaderProps) => {
  const { client, myJid } = useContext(XmppContext)
  const dispatch = useAppDispatch()

  const [whatsappStatus, setWhatsappStatus] = useState<string | undefined>(
    undefined,
  )

  const [accountStatusDialogOpen, setAccountStatusDialogOpen] =
    useState<boolean>(false)

  useEffect(() => {
    dispatch(getWhatsappStatus(client))
      .then(setWhatsappStatus)
      .catch((e) => {
        captureError(e, { origin: "Other" })
      })
  }, [client, dispatch])

  const searchVisible = useAppSelector(isGlobalSearchVisible)

  const handleShowSearch = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      // @ts-ignore
      e.currentTarget?.blur?.()
      dispatch(showGlobalSearch())
      if (isMobile) {
        setMobileRoute(mobileRoutes.SEARCH)
      }
    },
    [dispatch],
  )

  const handleCloseSearch = useCallback(() => {
    if (isMobile) {
      setMobileRoute(mobileRoutes.CHAT_LIST)
    }
    dispatch(hideGlobalSearch())
  }, [dispatch])

  const onMarkAllChatsReadClick = useCallback(() => {
    dispatch(markAllChatsAsRead(client))
  }, [client, dispatch])

  const onSetWhatsAppAccountStatus = () => {
    setAccountStatusDialogOpen(true)
  }

  const closeAccountStatusDialog = useCallback(() => {
    setAccountStatusDialogOpen(false)
  }, [])

  const onSaveAccountStatus = useCallback(
    async (status: string) => {
      closeAccountStatusDialog()
      setWhatsappStatus(status)
    },
    [closeAccountStatusDialog],
  )

  const onSignOut = useCallback(async () => {
    await client.stop()
    const cookies = new Cookies(null, { path: "/" })
    // Remove cookies with all possible attribute combinations to ensure proper deletion
    cookies.remove("email", { path: "/" })
    cookies.remove("email", { path: "/", domain: window.location.hostname })
    cookies.remove("email") // default case

    cookies.remove("password", { path: "/" })
    cookies.remove("password", { path: "/", domain: window.location.hostname })
    cookies.remove("password") // default case

    window.persistor.purge()
    if (window.ReactNativeWebView) {
      const message = JSON.stringify({ type: "SignOut", data: null })
      window.ReactNativeWebView.postMessage(message)
    } else {
      window.location.reload()
    }
  }, [client])

  const showSignOut =
    !isAndroid ||
    (isAndroid &&
      (isVersionAtLeast(window.ANDROID_APP_VERSION, "2.2.0") ||
        !window.ReactNativeWebView))

  const shouldShowAddDriver = posthog.isFeatureEnabled("driver-management")
  const [isAddDriverModalOpen, setIsAddDriverModalOpen] = useState(false)
  const { isDarkMode, toggleDarkMode } = useDarkMode()

  return (
    <>
      <WhatsappAccountStatusDialog
        isOpen={accountStatusDialogOpen}
        status={whatsappStatus}
        onSaveSuccess={onSaveAccountStatus}
        onClose={closeAccountStatusDialog}
      />
      <AddEditDriverModal
        isOpen={isAddDriverModalOpen}
        onActiveChatChange={onActiveChatChange}
        onClose={() => setIsAddDriverModalOpen(false)}
      />
      <div className={css.chatListHeader}>
        <div className="flex flex-row space-x-2 align-center">
          <Button
            icon={
              <span
                className={classNames(css.status, {
                  [css.offline]: client.status !== "online",
                })}
              ></span>
            }
            minimal
            onClick={onOpenChatInfo}
          >
            <b>{myJid.getLocal()}</b>
          </Button>
          <RoomLoading />
        </div>
        <div className="flex flex-row justify-items-end gap-1">
          <AddUserButton />
          <OverlaysProvider>
            <Tooltip content="Search in messages" position="left">
              <Button
                data-testid="global-search-button"
                minimal
                icon={<Icon icon="search" size={18} />}
                onClick={handleShowSearch}
              />
            </Tooltip>
            <SearchPopover isOpen={searchVisible} onClose={handleCloseSearch} />
          </OverlaysProvider>
          <JoinChatPopover onActiveChatChange={onActiveChatChange} />
          <Popover
            interactionKind="click"
            content={
              <Menu>
                <MenuItem
                  icon={<Icon icon={isDarkMode ? "flash" : "moon"} />}
                  text={
                    isDarkMode ? "Switch to light mode" : "Switch to dark mode"
                  }
                  onClick={toggleDarkMode}
                />
                {shouldShowAddDriver && (
                  <MenuItem
                    icon={<Icon icon="drive-time" />}
                    text="Add new driver"
                    onClick={() => setIsAddDriverModalOpen(true)}
                  />
                )}
                <MenuItem
                  icon={<Icon icon="tick" />}
                  text="Mark all as read"
                  onClick={onMarkAllChatsReadClick}
                  disabled={client.status !== "online"}
                />
                <MenuItem
                  icon={<Icon icon="new-text-box" />}
                  text="Set account status"
                  onClick={onSetWhatsAppAccountStatus}
                  disabled={client.status !== "online"}
                />
                {showSignOut && <Divider />}
                {showSignOut && (
                  <MenuItem
                    icon={<Icon icon="log-out" />}
                    text="Sign out"
                    onClick={onSignOut}
                    data-testid="logout"
                  />
                )}
              </Menu>
            }
            placement="bottom"
            renderTarget={({ isOpen, ...targetProps }) => (
              <Button
                data-testid="action-menu"
                {...targetProps}
                icon={
                  <img
                    src="/navi-menu.svg"
                    alt="Menu icon"
                    width={18}
                    height={18}
                  />
                }
                minimal
                small
              />
            )}
          />
        </div>
      </div>
    </>
  )
}

export default ChatListHeader
