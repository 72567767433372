import XmppGroupChatApi from "../api/xmppGroupChatApi"
import {
  ArchiveFetchResult,
  MAMQueryOptions,
  Order,
  iterateThroughArchivePages,
} from "../api/xeps/MAM"
import { Client } from "@xmpp/client"
import { addMilliseconds, isAfter, subDays, subHours } from "date-fns"
import {
  Chat,
  Marker,
  Message,
  GenericMessage,
  addBatchMessagesToChat,
  buildChat,
  multiAddMessageCorrection,
  multiMarkMessage,
  refreshChats,
  limitChatSize,
  Reaction,
  addBatchMessageReactions,
  MessageFileShared,
  addBatchMessageFileShares,
  DocumentSigned,
  addBatchDocumentsSigned,
  MessageRetraction,
  addBatchMessageRetractions,
} from "../reducers/chatsSlice"
import {
  updateLastSyncedAt,
  selectLastSyncedAt,
} from "../reducers/chatMetaSlice"
import { latestSentOrReceivedMessageInIM } from "../reducers/chatsSliceSelectors"
import { STATE_VERSION, type AppStore, type RootState } from "../reducers/store"
import { defer, redirect } from "react-router-dom"
import { REHYDRATE } from "redux-persist"
import SettingsApi from "../api/settingsApi"
import { updateSettings } from "../reducers/settingsSlice"
import {
  addToSyncQueue,
  clearSyncedAndFailedRooms,
  setInitSyncRoomCount,
  updatePreRoomSyncInProgress,
  updateSyncMetadata,
} from "../reducers/roomSyncStatusSlice"
import AdHocCommands from "../api/adHocCommands"
import MAMStanzaProcessor from "../api/xeps/MAMStanzaProcessor"
import {
  Bookmark,
  addBookmark,
  retrieveAllBookmarks,
} from "../api/xeps/PepNativeBookmarks"
import {
  attemptToSignInWithCookies,
  configureWindowXmpp,
  hasUserAuthCookies,
} from "./SignIn"
import * as Sentry from "@sentry/react"
import { captureError } from "../ErrorHandlers"
import ChatHelpers from "../lib/chatHelpers"
import { retry } from "../utils"
import { Dispatch } from "@reduxjs/toolkit"
import XmppApi from "../api/xmppApi"
import { setRoster } from "../reducers/rosterSlice"
import {
  markLoadingMetric,
  markMetricDuration,
  resetStart as resetLoadingMetricsStart,
  sendLoadingMetrics,
} from "../loadingMetrics"
import RoomSyncQueue from "../lib/roomSyncQueue"
import { getStoredMyJid } from "../lib/cookieStoredJidStorage"

const MAX_MESSAGES_IN_CHAT = 100
const RETRY_OPTIONS = { maxAttempts: 5, backoff: true }

const addMillisecond = (iso: string | Date): Date => {
  const date = new Date(iso)
  return addMilliseconds(date, 1)
}

export const fetchSettings = async (client: Client, store: AppStore) => {
  return Sentry.startSpan({ name: "FetchSettings" }, async () => {
    try {
      const settings = await SettingsApi.getSettings(client)
      if (!settings) return

      store.dispatch(updateSettings({ settings }))
    } catch (error: any) {
      captureError(error, {
        origin: "InitialLoad",
        extra: { message: "fetchSettings" },
      })
    }

    try {
      const pushSubscription =
        await AdHocCommands.getPushNotificationsSubscription(client)
      store.dispatch(
        updateSettings({
          settings: {
            pushSubscriptionPresent: !!pushSubscription,
          },
        }),
      )
    } catch (e) {
      store.dispatch(
        updateSettings({
          settings: {
            pushSubscriptionPresent: false,
          },
        }),
      )
    }
  })
}

type ArchiveFetchOptions = {
  queryOptions: MAMQueryOptions
  order: Order
  softMax?: number
}

const A_LOT_OF_ROOMS = 20
export const SOFT_MAX_MESSAGES_COUNT = 7
export const SOFT_MAX_MESSAGES_COUNT_MANY_CHATS = 5

const archiveQueryOptions = (state: RootState, chat?: Chat) => {
  const lastMessage = chat
    ? chat.messages.slice(-1)[0]
    : latestSentOrReceivedMessageInIM(state)

  const chatLastSyncedAt = chat
    ? selectLastSyncedAt(chat.jid)(state)
    : undefined

  let fetchOptions: ArchiveFetchOptions

  if (lastMessage) {
    const afterStamp = getTimestampToFetchFrom(lastMessage, chatLastSyncedAt)

    fetchOptions = {
      queryOptions: {
        afterStamp: afterStamp.toISOString(),
      },
      order: "asc",
    }
  } else {
    fetchOptions = {
      queryOptions: {
        before: true, // fetch last page of the archive
      },
      order: "desc",
      softMax:
        state.chats.length > A_LOT_OF_ROOMS
          ? SOFT_MAX_MESSAGES_COUNT_MANY_CHATS
          : SOFT_MAX_MESSAGES_COUNT,
    }
  }

  if (chat?.room) {
    fetchOptions.queryOptions.toJid = chat.jid
  }

  return fetchOptions
}

const getTimestampToFetchFrom = (
  lastMessage: GenericMessage,
  chatLastSyncedAt: Date | undefined,
): Date => {
  if (!chatLastSyncedAt) {
    return addMillisecond(getLatestTimestampOfMessage(lastMessage))
  }

  const latestMessageTimestamp = addMillisecond(
    getLatestTimestampOfMessage(lastMessage),
  )

  return isAfter(latestMessageTimestamp, chatLastSyncedAt)
    ? chatLastSyncedAt
    : latestMessageTimestamp
}

const getLatestTimestampOfMessage = (message: GenericMessage): Date => {
  let latestDate = extractTime(message)
  if ("markers" in message) {
    for (const marker of message.markers) {
      const markerDate = extractTime(marker)
      if (isAfter(markerDate, latestDate)) {
        latestDate = markerDate
      }
    }
  }

  return latestDate
}

const extractTime = (message: GenericMessage): Date => {
  if (
    "stanzaId" in message &&
    message.stanzaId &&
    message.stanzaId.match(/^\d{16}$/)
  ) {
    return new Date(Number(message.stanzaId) / 1000)
  }

  if ("id" in message && message.id && message.id.match(/^\d{16}$/)) {
    return new Date(Number(message.id) / 1000)
  }

  return new Date(message.timestamp)
}

type SyncRoomOptions = {
  modern?: boolean
}

export const lastSyncedAtIsFarBehind = (
  lastSyncedAt: Date | undefined,
  state: RootState,
): boolean => {
  if (!lastSyncedAt) return true

  const aWhileAgo =
    state.chats.length > A_LOT_OF_ROOMS
      ? subHours(new Date(), 12)
      : subDays(new Date(), 1)

  return isAfter(aWhileAgo, lastSyncedAt)
}

export const syncSingleRoom = async (
  jid: string | undefined,
  client: Client,
  state: RootState,
  dispatch: Dispatch,
  options: SyncRoomOptions = {},
) => {
  let chat = state.chats.find((c) => c.room && c.jid === jid)

  const chatJid = chat ? chat.jid : undefined

  return Sentry.startSpan(
    { name: "FetchArchiveChat", attributes: { chat: chatJid } },
    async () => {
      const timestampBeforeSync = new Date().toISOString()

      if (chat && options.modern) {
        if (
          lastSyncedAtIsFarBehind(selectLastSyncedAt(chat.jid)(state), state)
        ) {
          dispatch(limitChatSize({ jid: chat.jid, limit: 0 }))
          chat = { ...chat, messages: [] }
        }
      }

      const fetchOptions = archiveQueryOptions(state, chat)
      window.analytics.track("FetchArchiveChat", {
        fetchOptions,
      })
      const messagesAccumulator: Message[][] = []
      const markersAccumulator: Marker[][] = []
      const reactionsAccumulator: Reaction[][] = []
      const messageFileSharesAccumulator: MessageFileShared[][] = []
      const messageRetractionsAccumulator: MessageRetraction[][] = []
      const documentsSignedAccumulator: DocumentSigned[][] = []
      const stats = await iterateThroughArchivePages(
        client,
        fetchOptions.queryOptions,
        MAMStanzaProcessor.accumulatePages(
          messagesAccumulator,
          markersAccumulator,
          reactionsAccumulator,
          messageFileSharesAccumulator,
          messageRetractionsAccumulator,
          documentsSignedAccumulator,
          client,
        ),
        fetchOptions.order,
        fetchOptions.softMax,
      )

      if (chat?.jid) {
        const messageCount = messagesAccumulator.reduce(
          (initial, messages) => initial + messages.length,
          0,
        )
        const markersCount = markersAccumulator.reduce(
          (initial, messages) => initial + messages.length,
          0,
        )
        const reactionsCount = reactionsAccumulator.reduce(
          (initial, messages) => initial + messages.length,
          0,
        )
        const retractionsCount = messageRetractionsAccumulator.reduce(
          (initial, messages) => initial + messages.length,
          0,
        )

        dispatch(
          updateSyncMetadata({
            jid: chat.jid,
            messages: messageCount,
            markers: markersCount,
            reactions: reactionsCount,
            retractions: retractionsCount,
          }),
        )
      }

      const messages = MAMStanzaProcessor.normalizePagesAscending(
        messagesAccumulator,
        fetchOptions.order,
        !!fetchOptions.queryOptions.flipPage,
      )

      const groupedMessages =
        MAMStanzaProcessor.groupByMessagesAndCorrections(messages)

      dispatch(
        addBatchMessagesToChat({
          messages: groupedMessages["messages"],
          myJid: client.jid!.toString(),
          mode: fetchOptions.order === "asc" ? "append" : "prepend",
        }),
      )

      const corrections = groupedMessages["corrections"]
      dispatch(
        multiAddMessageCorrection({
          corrections,
          myJid: client.jid!.toString(),
        }),
      )

      const markersToDispatch = MAMStanzaProcessor.normalizePagesAscending(
        markersAccumulator,
        fetchOptions.order,
        !!fetchOptions.queryOptions.flipPage,
      )
      dispatch(
        multiMarkMessage({
          markers: markersToDispatch,
          myJid: client.jid!.toString(),
        }),
      )

      const reactionsToDispatch = MAMStanzaProcessor.normalizePagesAscending(
        reactionsAccumulator,
        fetchOptions.order,
        !!fetchOptions.queryOptions.flipPage,
      )
      dispatch(
        addBatchMessageReactions({
          reactions: reactionsToDispatch,
          myJid: client.jid!.toString(),
        }),
      )
      const messageFileSharesToDispatch =
        MAMStanzaProcessor.normalizePagesAscending(
          messageFileSharesAccumulator,
          fetchOptions.order,
          !!fetchOptions.queryOptions.flipPage,
        )

      dispatch(
        addBatchMessageFileShares({
          messageFileShares: messageFileSharesToDispatch,
        }),
      )

      const documentsSignedToDispatch =
        MAMStanzaProcessor.normalizePagesAscending(
          documentsSignedAccumulator,
          fetchOptions.order,
          !!fetchOptions.queryOptions.flipPage,
        )

      dispatch(
        addBatchDocumentsSigned({
          documentsSigned: documentsSignedToDispatch,
        }),
      )

      const messageRetractionsToDispatch =
        MAMStanzaProcessor.normalizePagesAscending(
          messageRetractionsAccumulator,
          fetchOptions.order,
          !!fetchOptions.queryOptions.flipPage,
        )

      dispatch(
        addBatchMessageRetractions({
          messageRetractions: messageRetractionsToDispatch,
        }),
      )

      if (chat) {
        dispatch(
          updateLastSyncedAt({
            jid: chat.jid,
            lastSyncedAt: timestampBeforeSync,
          }),
        )
        dispatch(limitChatSize({ jid: chat.jid, limit: MAX_MESSAGES_IN_CHAT }))
      }

      return stats
    },
  )
}

export const fetchAndStore = async (
  client: Client,
  store: AppStore,
  chat?: Chat,
): Promise<ArchiveFetchResult> => {
  const dispatch = store.dispatch
  const state = store.getState()

  return await syncSingleRoom(chat?.jid, client, state, dispatch)
}

const createInitialBookmarks = async (client: Client): Promise<Bookmark[]> => {
  const chats = await XmppGroupChatApi.fetchRooms(client)

  const { mandatory, optional } = chats.reduce(
    (res, chat) => {
      if (ChatHelpers.canUserLeaveChat(chat)) {
        res.optional.push(chat)
      } else {
        res.mandatory.push(chat)
      }

      return res
    },
    { mandatory: [], optional: [] } as { mandatory: Chat[]; optional: Chat[] },
  )

  const chatsToBookmark = [...mandatory, ...optional.slice(0, 5)]
  for (const chat of chatsToBookmark) {
    await addBookmark(client, chat.jid, chat.name)
  }

  return chatsToBookmark.map((chat) => ({
    jid: chat.jid,
    autojoin: true,
    name: chat.name,
  }))
}

const fetchBookmarks = async (client: Client): Promise<Bookmark[]> => {
  return Sentry.startSpan({ name: "FetchBookmarks" }, async () => {
    try {
      const bookmarks = await retrieveAllBookmarks(client)
      if (bookmarks.length === 0) return await createInitialBookmarks(client)
      return bookmarks
    } catch (e: any) {
      if (e.name === "StanzaError" && e.condition === "item-not-found") {
        return await createInitialBookmarks(client)
      } else {
        throw e
      }
    }
  })
}

const callGetRooms = async (client: Client): Promise<Chat[]> => {
  try {
    return await XmppGroupChatApi.fetchRooms(client)
  } catch (error) {
    console.warn(
      `Initial GetRooms failure ${error}, continuing sync without metadata`,
    )
    return []
  }
}

export const getBookmarkedRooms = async (client: Client) => {
  const bookmarks = await fetchBookmarks(client)
  const roomData = await callGetRooms(client)
  const chats = bookmarks.map((bookmark) => {
    const roomInfo = roomData.find((r) => r.jid === bookmark.jid)
    const groups = roomInfo ? roomInfo.groups : undefined
    return buildChat({
      room: true,
      jid: bookmark.jid,
      name: roomInfo?.name || bookmark.name,
      groups,
    })
  })

  return chats
}

export const performInitialLoad = async (
  client: Client,
  store: AppStore,
): Promise<Types.Api.Chat[]> => {
  window.analytics.track("InitialLoadStart")
  store.dispatch(updatePreRoomSyncInProgress(true))
  RoomSyncQueue.queue = []
  store.dispatch(clearSyncedAndFailedRooms())
  resetLoadingMetricsStart()

  const rosterTime = window.performance.now()

  const roster = await retry(XmppApi.fetchRoster, [client], RETRY_OPTIONS)
  store.dispatch(setRoster({ items: roster }))
  window.analytics.track("InitialLoadRosterFetch")
  markMetricDuration("rosterFetch", rosterTime)

  retry(fetchSettings, [client, store], RETRY_OPTIONS)

  const chatsTime = window.performance.now()
  const chats = await retry(getBookmarkedRooms, [client], RETRY_OPTIONS)
  window.analytics.track("InitialLoadBookmarksFetch")

  store.dispatch(refreshChats({ chats }))
  store.dispatch(setInitSyncRoomCount({ count: chats.length }))

  for (const chat of chats) {
    store.dispatch(addToSyncQueue({ jid: chat.jid }))
  }

  store.dispatch(updatePreRoomSyncInProgress(false))

  markMetricDuration("chatsFetch", chatsTime)

  markLoadingMetric("chatsLength", chats.length)
  sendLoadingMetrics()

  return store.getState().chats
}

export const loadChatsArchive = (store: AppStore) => {
  return async () => {
    // We first check for client on window because the native sign in flow
    // does not use cookies, instead configures window.XMPP on each app open
    if (!window.XMPP) {
      if (!hasUserAuthCookies()) {
        throw redirect("/signin")
      }

      configureWindowXmpp()
    }

    injectReactNativeStore(store)

    return defer({
      myJid: getMyJid(),
    })
  }
}

const getMyJid = async () => {
  if (window.ReactNativeWebView) {
    if (!window.myJID) {
      throw new Error("No window.myJID present in native")
    }
    return window.myJID.toString()
  }

  let myStoredJid = getStoredMyJid()

  if (!myStoredJid) {
    const jid = await connectXMPP()
    if (jid) {
      myStoredJid = jid.toString()
    }
  } else {
    await connectXMPP()
  }

  if (!myStoredJid) {
    window.location.href = "/signin"
    return
  }

  return myStoredJid
}

const connectXMPP = async () => {
  try {
    return await attemptToSignInWithCookies()
  } catch {
    return await retry(attemptToSignInWithCookies, [], {
      ...RETRY_OPTIONS,
      backoff: true,
    })
  }
}

const injectNativeStoreSlice = (
  store: AppStore,
  newState: Record<string, string>,
  key: string,
) => {
  if (!newState[key]) {
    console.warn(`Cannot revive ${key} because it's missing from new state`)
    return
  }

  const data = JSON.parse(newState[key])
  const payload: Record<string, object> = {}
  payload[key] = data
  store.dispatch({
    type: REHYDRATE,
    key: "root",
    payload,
  })
}

const injectReactNativeStore = (store: AppStore) => {
  const s = window.performance.now()
  try {
    if (window.injectedStore) {
      try {
        const newState = JSON.parse(window.injectedStore)
        const newStoreVersion = newState._persist
          ? JSON.parse(newState._persist).version
          : STATE_VERSION

        if (newStoreVersion < STATE_VERSION) {
          console.info(
            `Store migration, ${newStoreVersion} => ${STATE_VERSION}`,
          )

          window.analytics.track("PurgeStore", {
            reason: "state_version_changed",
            oldVersion: newStoreVersion,
            newVersion: STATE_VERSION,
          })

          return
        }

        const injectedKeys = [
          "chats",
          "sendoutQueue",
          "chatMeta",
          "profiles",
          "settings",
          "subjects",
          "routes",
          "activities",
          "chatReplies",
          "roster",
          "locations",
          "seenChats",
        ]
        let successfullyInjectedKeys = 0

        for (const key of injectedKeys) {
          try {
            injectNativeStoreSlice(store, newState, key)
            successfullyInjectedKeys += 1
          } catch {
            console.warn(`Error reviving injectedStore ${key}`)
          }
        }
        window.analytics.track("InjectNative", {
          success: true,
          successfullyInjectedKeys,
          totalKeys: injectedKeys.length,
        })
      } catch (error) {
        console.warn("Error reviving injectedStore")
        console.error(error)
        window.analytics.track("InjectNative", { success: false })
      }
    }
  } finally {
    markMetricDuration("injectNativeStore", s)
  }
}
