import React, { useCallback, useState, useMemo, useLayoutEffect } from "react"
import SignInForm from "../forms/SignInForm"
import * as Sentry from "@sentry/browser"

import utilsCss from "../components/utilsCss.module.css"
import css from "./SignIn.module.css"
import classNames from "classnames"
import {
  connect,
  resetCredentialsAndConnect,
  setXmppOnWindow,
} from "../stream/xmppClient"
import { useNavigate } from "react-router-dom"
import { useAppStore } from "../reducers/hooks"
import Cookies from "universal-cookie"
import { Callout } from "@blueprintjs/core"
import posthog from "posthog-js"

const TWO_WEEKS = 12096e5

export const setupAnalytics = (userAuth: Types.Api.UserAuth) => {
  window.performance.mark("SetupAnalytics")
  Sentry.setUser({ email: userAuth.email })
  posthog.identify(userAuth.email, { vhost: userAuth.email.split("@").at(-1) })
  window.analytics.identify(userAuth.email, {
    email: userAuth.email,
    appVersion: window.APP_VERSION,
  })
}

const getUserAuthFromCookies = () => {
  const cookieOptions = {
    path: "/",
    expires: new Date(Date.now() + TWO_WEEKS),
    domain: window.location.hostname,
    secure: window.location.hostname !== "localhost",
  }

  const cookies = new Cookies(null, cookieOptions)
  const email = cookies.get("email") as string | undefined
  const password = cookies.get("password") as string | undefined

  if (email && password) {
    return { email, password }
  }

  return null
}

export const hasUserAuthCookies = () => {
  return !!getUserAuthFromCookies()
}

export const configureWindowXmpp = () => {
  const userAuth = getUserAuthFromCookies()
  if (!userAuth) {
    throw "No cookies with auth information"
  }

  return setXmppOnWindow(userAuth)
}

export const attemptToSignInWithCookies = async () => {
  window.performance.mark("AttemptToSignInWithCookie")
  const userAuth = getUserAuthFromCookies()
  if (!userAuth) {
    throw "No cookies with auth information"
  }

  try {
    const result = await connect(window.store)

    if (result.connected) {
      setupAnalytics(userAuth)
      return result.jid
    }

    if (result.error === "not-authorized") {
      return null
    }

    throw "Failed attempt to signin with cookies"
  } catch (error) {
    console.warn("Failed attempt to signin with cookies")
    Sentry.captureException(error)
    throw "Failed attempt to signin with cookies"
  }
}

const SignIn = () => {
  const [isFailed, setIsFailed] = useState(false)
  const navigate = useNavigate()
  const store = useAppStore()
  const cookieOptions = useMemo(() => {
    return {
      path: "/",
      expires: new Date(Date.now() + TWO_WEEKS),
      domain: window.location.hostname,
      secure: import.meta.env.REACT_APP_ENV_NAME !== "development",
    }
  }, [])

  const handleOnSubmit = useCallback(
    (userAuth: Types.Api.UserAuth) => {
      setIsFailed(false)
      resetCredentialsAndConnect(userAuth, store)
        .then(({ connected: isConnected }) => {
          if (isConnected) {
            const cookies = new Cookies(null, cookieOptions)
            cookies.set("email", userAuth.email)
            cookies.set("password", userAuth.password)
            setupAnalytics(userAuth)
            window.analytics.track("LoggedIn")
            navigate("/", { replace: true })
          } else {
            setIsFailed(true)
          }
        })
        .catch(() => {
          setIsFailed(true)
        })
    },
    [store, navigate, cookieOptions],
  )

  useLayoutEffect(() => {
    if (window.XMPP && window.XMPP.status === "online") {
      navigate("/", { replace: true })
    }
  }, [navigate, store])

  return (
    <div className={classNames(css.signInForm, utilsCss.center)}>
      {isFailed && (
        <Callout intent="danger">Invalid username or password</Callout>
      )}
      {<SignInForm onSubmit={handleOnSubmit} />}
    </div>
  )
}

export default SignIn
