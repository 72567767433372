import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react"
import { Loader } from "@googlemaps/js-api-loader"

export type Address = {
  city: string
  countryCode: string
  formattedAddress: string
}

export type GoogleMapsContextType = {
  places: google.maps.PlacesLibrary | null
  geocoder: google.maps.Geocoder | null
  isLoading: boolean
  error: Error | null
  reverseGeocode: (
    lat: number | string,
    lng: number | string,
  ) => Promise<Address>
}

export const GoogleMapsContext = createContext<
  GoogleMapsContextType | undefined
>(undefined)

const loader = new Loader({
  apiKey: import.meta.env.REACT_APP_GOGLE_MAP_KEY || "",
  libraries: ["places", "geocoding"],
})

export const GoogleMapsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [places, setPlaces] = useState<google.maps.PlacesLibrary | null>(null)
  const [geocoder, setGeocoder] = useState<google.maps.Geocoder | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    Promise.all([
      loader.importLibrary("places"),
      loader.importLibrary("geocoding"),
    ])
      .then(([placesLib]) => {
        setPlaces(placesLib as google.maps.PlacesLibrary)
        setGeocoder(new google.maps.Geocoder())
        setIsLoading(false)
      })
      .catch((err) => {
        setError(err)
        setIsLoading(false)
      })
  }, [])

  const reverseGeocode = useCallback(
    async (lat: number | string, lng: number | string): Promise<Address> => {
      if (!geocoder) {
        throw new Error("Geocoder not initialized")
      }
      const coordinates: google.maps.LatLngLiteral = {
        lat: typeof lat === "string" ? parseFloat(lat) : lat,
        lng: typeof lng === "string" ? parseFloat(lng) : lng,
      }
      return new Promise((resolve, reject) => {
        void geocoder.geocode({ location: coordinates }, (results, status) => {
          if (status === "OK" && results && results[0]) {
            const result = results[0]
            let city = ""
            let country = ""
            result.address_components.forEach((component) => {
              if (component.types.includes("locality")) {
                city = component.long_name
              }
              if (component.types.includes("country")) {
                country = component.short_name
              }
            })
            resolve({
              city,
              countryCode: country,
              formattedAddress: result.formatted_address,
            })
          } else {
            reject(new Error(`Geocoding failed: ${status}`))
          }
        })
      })
    },
    [geocoder],
  )

  const value: GoogleMapsContextType = {
    places,
    geocoder,
    isLoading,
    error,
    reverseGeocode,
  }

  return (
    <GoogleMapsContext.Provider value={value}>
      {children}
    </GoogleMapsContext.Provider>
  )
}

export const useGoogleMaps = (): GoogleMapsContextType => {
  const context = useContext(GoogleMapsContext)
  if (context === undefined) {
    throw new Error("useGoogleMaps must be used within a GoogleMapsProvider")
  }
  return context
}
