import React, { useMemo, useState, useEffect } from "react"
import { Label, Icon, Button, MenuItem, Tooltip } from "@blueprintjs/core"
import { ItemPredicate, ItemRenderer, Select } from "@blueprintjs/select"
import utilsCss from "./utilsCss.module.css"
import css from "./GpsDeviceSelector.module.css"

import { GpsDevice } from "../hooks/useGetGpsDevices"

type GpsDeviceSelectorProps = {
  gpsDevices: GpsDevice[]
  currentGpsDeviceValue?: string | null
  onDeviceChange: (deviceValue: string | null) => void
  withoutLabel?: boolean
}

const GpsDeviceSelector = ({
  gpsDevices,
  currentGpsDeviceValue,
  onDeviceChange,
  withoutLabel = false,
}: GpsDeviceSelectorProps) => {
  const noDevice: GpsDevice = {
    label: "Select driver's GPS",
    value: null,
  }
  const [isLoading, setIsLoading] = useState<boolean>(true)

  function findGpsDeviceLabelByValue(
    gpsDevices: GpsDevice[],
    searchValue?: string | null,
  ): string {
    const gpsDeviceObj: GpsDevice =
      gpsDevices.find((device) => device.value === searchValue) || noDevice
    return gpsDeviceObj.label
  }

  const sortedDevices = useMemo(() => {
    return [...gpsDevices].sort((a, b) => a.label.localeCompare(b.label))
  }, [gpsDevices])

  const renderDevice: ItemRenderer<GpsDevice> = (device) => {
    return (
      <MenuItem
        selected={currentGpsDeviceValue === device.value}
        key={device.value}
        text={<div>{device.label}</div>}
        active={device.value === currentGpsDeviceValue}
        onClick={(e) => {
          e.preventDefault
          onDeviceChange(device.value)
        }}
      />
    )
  }

  const filterDevices: ItemPredicate<GpsDevice> = (
    query,
    device,
    _index,
    exactMatch,
  ) => {
    const normalizedQuery = query.toLowerCase()
    const normalizedDevice = device.label.toLowerCase()
    if (exactMatch) {
      return normalizedDevice === normalizedQuery
    } else {
      return normalizedDevice.indexOf(normalizedQuery) >= 0
    }
  }

  useEffect(() => {
    if (currentGpsDeviceValue || withoutLabel) {
      setIsLoading(false)
    }
  }, [currentGpsDeviceValue])

  const gpsSelector = (
    <div className={css.flexContainer}>
      <Select<GpsDevice>
        items={sortedDevices}
        itemPredicate={filterDevices}
        itemRenderer={renderDevice}
        scrollToActiveItem
        noResults={
          <MenuItem
            disabled
            text={<div className={utilsCss.noResults}>No Results</div>}
          />
        }
        onItemSelect={(gpsDevice) => onDeviceChange(gpsDevice.value)}
        menuProps={{
          className: css.gpsDeviceSelectorContainer,
        }}
        popoverProps={{ matchTargetWidth: true, minimal: true }}
      >
        <Button
          data-testid="select-gps-device-button"
          rightIcon={<Icon icon="caret-down" size={16} />}
          alignText="left"
          fill
          disabled={isLoading}
          text={
            isLoading
              ? "Loading..."
              : findGpsDeviceLabelByValue(gpsDevices, currentGpsDeviceValue)
          }
        />
      </Select>
      <Tooltip>
        <Button
          data-testid="unassign-gps-device-button"
          icon={<Icon icon="trash" size={20} />}
          minimal
          disabled={currentGpsDeviceValue === "no device" || isLoading}
          onClick={() => {
            onDeviceChange(noDevice.value)
          }}
        />
      </Tooltip>
    </div>
  )

  if (withoutLabel) return gpsSelector

  return (
    <>
      <div className={css.fullWidth}>
        <Label>
          GPS Device
          {gpsSelector}
        </Label>
      </div>
    </>
  )
}

export default GpsDeviceSelector
