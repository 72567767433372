import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { PURGE } from "redux-persist"
import { RosterItem } from "../api/xmppApi"

export type SeenChatsState = string[]

const initialState: SeenChatsState = []

const seenChats = createSlice({
  name: "seenChats",
  initialState,
  reducers: {
    addToSeenChats: (state, action: PayloadAction<string[]>) => {
      action.payload.forEach((newJid) => {
        if (!state.find((jid) => jid === newJid)) {
          state.push(newJid)
        }
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState)
  },
})

export const { addToSeenChats } = seenChats.actions

export default seenChats.reducer
