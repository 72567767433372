import React, { useState } from "react"
import { Button, Icon, Tooltip } from "@blueprintjs/core"
import { AddEditUserModal } from "./AddEditUserModal"
import posthog from "posthog-js"

export default function AddUserButton() {
  const shouldShowAddUser = posthog.isFeatureEnabled("external-contacts")

  const [isModalOpen, setIsModalOpen] = useState(false)
  return shouldShowAddUser ? (
    <>
      <Tooltip content="Add new external contact" position="left">
        <Button
          minimal
          icon={<Icon icon="new-person" size={18} />}
          onClick={(e) => {
            e.stopPropagation()
            e.currentTarget?.blur?.()
            setIsModalOpen(true)
          }}
        />
      </Tooltip>
      <AddEditUserModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  ) : null
}
