import { Element } from "@xmpp/xml"

type MucInvitation = {
  jid: string
}

class MucInvitationParser {
  static parse(stanza: Element): MucInvitation | undefined {
    if (stanza.name !== "message") return undefined

    const x = stanza.getChild("x")
    if (!x) return undefined

    if (x.attrs.xmlns !== "jabber:x:conference") return undefined

    const jid = x.attrs.jid
    return {
      jid: jid,
    }
  }
}

export default MucInvitationParser
