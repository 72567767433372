import { useEffect } from "react"

export default function useSetIntervalInForeground({
  fn,
  interval,
  deps,
}: {
  fn: () => void
  interval: number
  deps: any[]
}) {
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (document.visibilityState === "visible") {
        fn()
      }
    }, interval)
    return () => clearInterval(intervalId)
  }, deps)
}
