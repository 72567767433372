import React, { memo, RefObject, useContext, useMemo } from "react"
import css from "./MessageRow.module.css"
import { type Message } from "../../reducers/chatsSlice"
import MessageHelpers, { MessageStatus } from "../../lib/messageHelpers"
import { Icon, Menu, MenuItem, Popover } from "@blueprintjs/core"
import AvatarImage, { participantPhotoToSrc } from "../AvatarImage"
import classNames from "classnames"
import { useAppDispatch, useAppSelector } from "../../reducers/hooks"
import {
  findAuthorProfile,
  messageAuthorName,
} from "../../reducers/profilesSliceSelectors"
import {
  selectActiveChat,
  selectDriverParticipant,
} from "../../reducers/chatsSliceSelectors"
import { ChatInputTextAreaRef } from "../ChatInput"
import { useMessageSwipe } from "../../hooks/useMessageSwipe"
import { MESSAGE_HIGHLIGHT_DURATION_MS } from "../ChatHistory"
import useMessageHighlight from "../../hooks/useMessageHighlight"
import { setChatReplyMessage } from "../../reducers/chatRepliesSlice"
import { XmppContext } from "../../stream/xmppClient"
import useMessageReactionPicker from "../../hooks/useMessageReactionPicker"
import { MessageBubbleToolbarContext } from "../../contexts/MessageBubbleToolbarContext"
import { isMobile } from "../../utils/detectDevice"
import useSetSigned from "../../hooks/useSetSigned"
import { CircleEllipsis } from "lucide-react"

type MessageAuthorProps = {
  message: Message
}

export const MessageAuthor = ({ message }: MessageAuthorProps) => {
  const { myJid } = useContext(XmppContext)
  const authorName = useAppSelector(messageAuthorName(message, myJid))
  const activeChat = useAppSelector(selectActiveChat)
  const driverParticipant = useAppSelector(
    selectDriverParticipant(activeChat.jid),
  )

  const isMessageFromDriver = MessageHelpers.hasComeFromJid(
    message,
    driverParticipant?.jid,
  )

  return (
    <span
      className={classNames(
        "text-xs font-bold",
        isMessageFromDriver && "text-active-chat",
      )}
    >
      {authorName}
    </span>
  )
}

type MessageRowProps = {
  className?: string
  messageForRowActions: Message
  status?: MessageStatus | undefined
  grouped?: boolean
  chatInputRef: RefObject<ChatInputTextAreaRef>
  scrollToMessage?: (id: string) => void
  isImageGroup?: boolean
  children: React.ReactNode
  onDelete: (message: Message) => void
}

const MessageRow = ({
  messageForRowActions,
  status,
  grouped = false,
  className,
  chatInputRef,
  scrollToMessage,
  isImageGroup = false,
  children,
  onDelete,
  ...props
}: MessageRowProps) => {
  const { myJid } = useContext(XmppContext)
  const authorProfile = useAppSelector(
    findAuthorProfile(messageForRowActions, myJid.getDomain()),
  )
  const dispatch = useAppDispatch()
  const activeChat = useAppSelector(selectActiveChat)
  const authorName = useAppSelector(
    messageAuthorName(messageForRowActions, myJid),
  )
  // Prevent replying to missed calls messages
  // as they do not exist on the WhatsApp side
  const canBeRepliedTo = !messageForRowActions.missedCallNumber && !isImageGroup

  const selectAsMessageToReply = () => {
    if (!canBeRepliedTo) return

    dispatch(
      setChatReplyMessage({
        chatJid: activeChat.jid,
        message: messageForRowActions,
      }),
    )
    chatInputRef.current?.focus()
  }

  const reactionPicker = useMessageReactionPicker()
  const messageSwipe = useMessageSwipe(() => selectAsMessageToReply())

  const isHighlighted = useMessageHighlight(messageForRowActions)
  const isIncoming = useMemo(
    () => MessageHelpers.isIncoming(messageForRowActions, myJid),
    [messageForRowActions, myJid],
  )

  const messageBubbleToolbarContextValue = useMemo(() => {
    return { reactionPicker }
  }, [reactionPicker])

  const onShowReactionPicker = () => {
    reactionPicker.toolbarRef.current?.openEmojiPicker()
  }

  const copyMessageBodyToClipboard = () => {
    navigator.clipboard.writeText(messageForRowActions.body)
  }

  const isMineMessage = useMemo(
    () => MessageHelpers.hasComeFromJid(messageForRowActions, myJid.toString()),
    [messageForRowActions, myJid],
  )

  const onMarkAsSigned = useSetSigned(messageForRowActions, true)
  const onMarkAsNotSigned = useSetSigned(messageForRowActions, false)

  const onMobileDelete = () => onDelete(messageForRowActions)

  return (
    <div
      data-testid={messageForRowActions.id}
      {...props}
      style={{ animationDuration: `${MESSAGE_HIGHLIGHT_DURATION_MS}ms` }}
      className={classNames(isHighlighted && "animate-[message-bg-fade]")}
    >
      <div
        {...(canBeRepliedTo
          ? {
              ...messageSwipe.handlers,
              ref: messageSwipe.ref,
            }
          : {})}
        className={classNames(
          className,
          css.messageContainer,
          "relative flex",
          {
            [css.grouped]: grouped,
          },
        )}
      >
        <Icon icon="undo" className="absolute -left-4 top-[calc(50%-8px)]" />
        <AvatarImage
          name={authorName}
          className={classNames({ [css.hide]: grouped })}
          src={participantPhotoToSrc(authorProfile?.photo)}
        />
        <div
          data-testid="message-body"
          ref={reactionPicker.containerRef}
          className={classNames("relative group", css.message, {
            [css.incomingMessage]: isIncoming,
            [css.outgoingMessage]: !isIncoming,
            [css.messageArrow]: !grouped,
            "animate-[highlight-message_0.5s_linear_0.1s]": isHighlighted,
          })}
        >
          <MessageBubbleToolbarContext.Provider
            value={messageBubbleToolbarContextValue}
          >
            {children}
          </MessageBubbleToolbarContext.Provider>
        </div>
        {isMobile && !isImageGroup && !messageForRowActions.retracted && (
          <div className="flex flex-col justify-center gap-y-3">
            <div
              data-testid="reaction-picker"
              className="text-dark-gray"
              onClick={onShowReactionPicker}
            >
              <Icon icon="emoji" size={17} />
            </div>
            <div>
              <Popover
                enforceFocus={false}
                placement="left"
                content={
                  <Menu>
                    <MenuItem
                      text="Copy"
                      onClick={copyMessageBodyToClipboard}
                    />
                    {isMineMessage && (
                      <MenuItem
                        intent="danger"
                        text="Delete"
                        onClick={onMobileDelete}
                      />
                    )}
                    {onMarkAsNotSigned && (
                      <MenuItem
                        text="Remove signed mark"
                        onClick={onMarkAsNotSigned}
                      />
                    )}
                    {onMarkAsSigned && (
                      <MenuItem
                        text="Mark CMR as signed"
                        onClick={onMarkAsSigned}
                      />
                    )}
                  </Menu>
                }
              >
                <CircleEllipsis size={18} className="text-dark-gray" />
              </Popover>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(MessageRow)
