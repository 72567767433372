import { Client } from "@xmpp/client"
import { Element } from "@xmpp/xml"
import { DataFormsParser } from "../../lib/formParser"
import AdHocCommands from "../../api/adHocCommands"
import { joinChat } from "../../reducers/chatsSliceThunks"
import { retry } from "../../utils"
import XmppApi from "../../api/xmppApi"
import { setRoster } from "../../reducers/rosterSlice"
import type { AppStore } from "../../reducers/store"

export interface MucInvitation {
  jid: string // The JID of the MUC room
  from?: string // The JID of the user who sent the invitation
  reason?: string // Optional reason/message for the invitation
  password?: string // Optional room password if the room is protected
}

export const waitForMucInvitation = (xmpp: Client): Promise<MucInvitation> => {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      xmpp.removeListener("stanza", stanzaHandler)
      reject(new Error("Timeout waiting for MUC invitation"))
    }, 15000)

    const stanzaHandler = async (stanza: Element) => {
      if (stanza.name === "message") {
        const xElement = stanza.getChild("x", "jabber:x:conference")

        if (xElement) {
          const mucInvitation: MucInvitation = {
            jid: xElement.attrs.jid,
            from: stanza.attrs.from,
            reason: xElement.attrs.reason,
            password: xElement.attrs.password,
          }

          clearTimeout(timeout)
          xmpp.removeListener("stanza", stanzaHandler)
          resolve(mucInvitation)
        }
      }
    }

    xmpp.on("stanza", stanzaHandler)
  })
}

export const parseDriverAndMucJids = (response: Element) => {
  const command = response.getChild("command")
  const form = command && DataFormsParser.parse(command)

  const driverJID = form?.fields?.["user_jid"]?.values[0]
  const mucJID = form?.fields?.["muc_jid"]?.values[0]

  return { driverJID, mucJID }
}

export const handleInvitationResponse = async (
  invitationPromise: Promise<{ jid: string }>,
  client: Client,
  store: AppStore,
  onActiveChatChange: (jid: string) => void,
  mucJID: string,
) => {
  try {
    const invitation = await invitationPromise
    const chat = {
      jid: invitation.jid,
      name: invitation.jid,
      room: true,
    }
    store.dispatch(joinChat(client, store, chat))
    onActiveChatChange(mucJID)
  } catch (e) {
    console.error("Failed to receive MUC invitation:", e)
  }
}

export const updateDriverGPSDevice = async (
  client: Client,
  driverJID: string,
  GPS: string,
) => {
  await AdHocCommands.setDriverGPSDevice(client, driverJID, GPS ?? null).catch(
    () => {
      window.appToaster.show({
        message: "Sorry, but we could not update GPS device",
        intent: "danger",
        timeout: 20,
      })
    },
  )
}

export const fetchRooster = async (client: Client, store: AppStore) => {
  // Fetch the XMPP roster (list of contacts/drivers) with retry logic in case of network issues
  // and update the Redux store with the fetched roster data

  const roster = await retry(XmppApi.fetchRoster, [client], {
    maxAttempts: 5,
    backoff: true,
  })
  store.dispatch(setRoster({ items: roster }))
}
