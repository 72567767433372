import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import parse from "@xmpp/xml/lib/parse"
import { PURGE, REHYDRATE } from "redux-persist"
import type { AppStore } from "../reducers/store"
import posthog from "posthog-js"

export type SendoutQueue = SendoutQueueItem[]

type MessageType = "chat" | "groupchat"

export type SendoutQueueItem = {
  stanza: string
  messageId: string
  messageType: MessageType
  addedAt?: string
  targetJid: string
  failed: boolean
}

export type SetAction = {
  queue: SendoutQueue
}

export type AddAction = {
  stanza: string
  messageId: string
  messageType: MessageType
}

export type MarkSendingAction = {
  messageId: string
}

export type MarkSentAction = {
  messageIds: string[]
}

export type MarkFailedAction = {
  messageId: string
}

const initialState: SendoutQueue = []

const notifyReactNative = (queue: SendoutQueue) => {
  if (
    window.ReactNativeWebView &&
    posthog.isFeatureEnabled("mobile-sendout-worker")
  ) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: "SendoutQueueChange",
        data: queue,
      }),
    )
  }
}

const sendoutQueueSlice = createSlice({
  name: "sendoutQueue",
  initialState,
  reducers: {
    setQueue: (state: SendoutQueue, action: PayloadAction<SetAction>) => {
      state.splice(0, state.length, ...action.payload.queue)
    },
    add: (state: SendoutQueue, action: PayloadAction<AddAction>) => {
      const element = parse(action.payload.stanza)
      const targetJid = element.attrs.to
      state.push({
        failed: false,
        addedAt: new Date().toISOString(),
        ...action.payload,
        targetJid,
      })
      notifyReactNative(state)
    },
    markSending: (
      state: SendoutQueue,
      action: PayloadAction<MarkSendingAction>,
    ) => {
      const item = state.find(
        (item) => item.messageId === action.payload.messageId,
      )
      if (item) {
        item.failed = false
        notifyReactNative(state)
      }
    },
    markRetrying: (
      state: SendoutQueue,
      action: PayloadAction<MarkSendingAction>,
    ) => {
      const item = state.find(
        (item) => item.messageId === action.payload.messageId,
      )
      if (item) {
        item.addedAt = new Date().toISOString()
        item.failed = false
        notifyReactNative(state)
      }
    },
    markSent: (state, action: PayloadAction<MarkSentAction>) => {
      const newlySentIds = new Set(action.payload.messageIds)
      const newState = []
      for (const item of state) {
        if (newlySentIds.has(item.messageId)) {
          console.log(`[SendoutQueueWorker] Acking ${item.messageId}`)
          window.analytics.track("Sendout.MarkSent", {
            messageId: item.messageId,
          })
        } else {
          newState.push(item)
        }
      }
      state.splice(0, state.length, ...newState)
      notifyReactNative(state)
    },
    markFailed: (state, action: PayloadAction<MarkFailedAction>) => {
      const item = state.find((e) => e.messageId === action.payload.messageId)

      if (item) {
        console.warn(
          `[SendoutQueueWorker] Tried too many times, aborting ${item.messageId}`,
        )
        window.analytics.track("Sendout.Failed", { messageId: item.messageId })
        item.failed = true
        notifyReactNative(state)
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action) => {
      // @ts-ignore
      const sendoutQueue: SendoutQueue = action?.payload?.sendoutQueue
      if (sendoutQueue) {
        for (const e of sendoutQueue) {
          state.push(e)
          if (!e.failed && e.messageType === "groupchat") {
            console.info(`[XEP-0198] Retrying from store ${e.messageId}`)
            window.analytics.track("Sendout.RetryFromStore", {
              messageId: e.messageId,
            })
            setTimeout(() => {
              window.sendoutQueueWorker.send(e.messageId)
            }, 10000)
          }
        }
        notifyReactNative(state)
      }
    })
    builder.addCase(PURGE, () => {
      notifyReactNative(initialState)
      return initialState
    })
  },
})

type BatchAckerParams = {
  debounce: number
  store: AppStore
}

type AckerOpts = {
  noDebounce?: boolean
}

export const BatchAcker = ({ debounce, store }: BatchAckerParams) => {
  let timeoutHandler: number | undefined = undefined
  const batch: string[] = []

  const dispatchBatch = (ids: string[]) => {
    store.dispatch(markSent({ messageIds: ids }))
  }

  const acker = (id: string, opts: AckerOpts = {}) => {
    batch.push(id)
    if (opts.noDebounce) {
      dispatchBatch(batch)
      batch.splice(0)
    } else {
      clearTimeout(timeoutHandler)
      timeoutHandler = window.setTimeout(() => {
        dispatchBatch(batch)
        batch.splice(0)
      }, debounce)
    }
  }

  return acker
}

export const {
  setQueue,
  add,
  markSent,
  markFailed,
  markSending,
  markRetrying,
} = sendoutQueueSlice.actions

export default sendoutQueueSlice.reducer
