import React, { useState, useEffect, useContext, useCallback } from "react"
import AdHocCommands from "../../api/adHocCommands"
import {
  Dialog,
  DialogBody,
  FormGroup,
  InputGroup,
  Button,
  Classes,
  DialogFooter,
} from "@blueprintjs/core"
import css from "./AddEditDriverModal.module.css"
import XMPPError from "@xmpp/error"
import GpsDeviceSelector from "../GpsDeviceSelector"
import { XmppContext } from "../../stream/xmppClient"
import useGetGpsDevices from "../../hooks/useGetGpsDevices"
import { useAppStore } from "../../reducers/hooks"
import {
  fetchRooster,
  handleInvitationResponse,
  parseDriverAndMucJids,
  updateDriverGPSDevice,
  waitForMucInvitation,
} from "./utils"
import { retry } from "../../utils"
import XmppApi from "../../api/xmppApi"
import { setRoster } from "../../reducers/rosterSlice"

interface AddEditDriverModalProps {
  isOpen: boolean
  onClose: (event?: any) => void
  phoneNumber?: string
  firstName?: string
  lastName?: string
  plates?: string
  driverJID?: string
  GPS?: { label: string; value: string | null }
  onActiveChatChange: (chatJid: string) => void
}

export const AddEditDriverModal: React.FC<AddEditDriverModalProps> = ({
  isOpen,
  onClose,
  driverJID,
  onActiveChatChange,
  ...rest
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState(rest.phoneNumber ?? "")
  const [firstName, setFirstName] = useState(rest.firstName ?? "")
  const [lastName, setLastName] = useState(rest.lastName ?? "")
  const [plates, setPlates] = useState(rest.plates ?? "")
  const [GPS, setGPS] = useState<{
    label: string
    value: string | null
  } | null>(rest.GPS ?? null)

  const store = useAppStore()

  const isEdit = driverJID !== undefined

  const { client } = useContext(XmppContext)
  const { gpsDevices } = useGetGpsDevices(
    client,
    isEdit ? driverJID : (client.jid?.toString() ?? null),
  )

  useEffect(() => {
    setPhoneNumber(rest.phoneNumber ?? "")
    setFirstName(rest.firstName ?? "")
    setLastName(rest.lastName ?? "")
    setPlates(rest.plates ?? "")
  }, [rest.phoneNumber, rest.firstName, rest.lastName])

  const handleSubmit = async (e: React.FormEvent) => {
    setIsSubmitting(true)
    e.preventDefault()

    try {
      // Promise to wait for multi-user chat (MUC) invitation after driver is added
      const invitationPromise = waitForMucInvitation(client)

      // Send command to add new driver with provided details
      const response = await AdHocCommands.addDriver({
        client,
        phoneNumber: phoneNumber,
        firstName: firstName,
        lastName: lastName,
        plates: plates,
      })

      const { driverJID, mucJID } = parseDriverAndMucJids(response)

      await fetchRooster(client, store)

      // Wait for MUC invitation and accept it

      if (mucJID) {
        await handleInvitationResponse(
          invitationPromise,
          client,
          store,
          onActiveChatChange,
          mucJID,
        )
      }

      if (driverJID && GPS?.value) {
        await updateDriverGPSDevice(client, driverJID, GPS.value)
      }
      handleClose()
    } catch (e) {
      const error = (e as XMPPError).text

      window.appToaster.show({
        message: error,
        intent: "danger",
        timeout: 2000,
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleClose = useCallback(() => {
    setPhoneNumber("")
    setFirstName("")
    setLastName("")
    setPlates("")
    setGPS(null)
    onClose()
  }, [])

  return (
    <Dialog
      isOpen={isOpen}
      canEscapeKeyClose
      canOutsideClickClose={false}
      onClose={handleClose}
      isCloseButtonShown={!isSubmitting}
      title={isEdit ? "Edit driver" : "Add new driver"}
      className="bp4-dialog-container w-full md:max-w-3xl mx-auto"
    >
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="flex flex-col">
          <DialogBody className={css.addUserModalInput}>
            <div className="flex flex-col gap-6">
              <FormGroup label="Firstname" labelFor="firstname">
                <InputGroup
                  large
                  id="first_name"
                  fill={true}
                  value={firstName}
                  onValueChange={(value) => setFirstName(value)}
                />
              </FormGroup>

              <FormGroup label="Surname" labelFor="surname">
                <InputGroup
                  large
                  id="last_name"
                  fill={true}
                  value={lastName}
                  onValueChange={(value) => setLastName(value)}
                />
              </FormGroup>

              <FormGroup label="Phone number" labelFor="phone">
                <InputGroup
                  id="phone"
                  large
                  required
                  placeholder="+48"
                  fill={true}
                  value={phoneNumber}
                  onValueChange={(value) =>
                    setPhoneNumber(value.replace(/\s+/g, ""))
                  }
                />
              </FormGroup>

              <FormGroup label="Plates" labelFor="plates">
                <InputGroup
                  id="plates"
                  large
                  required
                  placeholder=""
                  fill={true}
                  value={plates}
                  onValueChange={(value) => setPlates(value)}
                />
              </FormGroup>

              <FormGroup label="GPS">
                <GpsDeviceSelector
                  currentGpsDeviceValue={GPS?.value}
                  gpsDevices={gpsDevices}
                  withoutLabel
                  onDeviceChange={(value) =>
                    setGPS(gpsDevices.find((x) => x.value === value) ?? null)
                  }
                />
              </FormGroup>
            </div>
          </DialogBody>
          <DialogFooter>
            <div className="flex justify-end gap-3">
              <Button
                disabled={isSubmitting}
                text="Cancel"
                large
                intent="danger"
                onClick={handleClose}
                className={Classes.MINIMAL}
              />
              <Button
                type="submit"
                loading={isSubmitting}
                large
                intent="primary"
                text={isEdit ? "Save" : "Add"}
              />
            </div>
          </DialogFooter>
        </div>
      </form>
    </Dialog>
  )
}
