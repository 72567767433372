import { Client, xml } from "@xmpp/client"
import { v4 as uuid } from "uuid"
import { Element } from "@xmpp/xml"
import type { MessageType } from "../reducers/chatsSlice"

const XmppSimsApi = {
  buildMessage: (
    xmpp: Client,
    file: File,
    to: string,
    uri: string,
    type: MessageType,
    desc?: string,
  ): Element => {
    const id = uuid()

    const message = xml(
      "message",
      { to, from: xmpp.jid, id, type },
      xml(
        "file-sharing",
        { xmlns: "urn:xmpp:sfs:0", disposition: "inline" },
        xml(
          "sources",
          {},
          xml("url-data", {
            xmlns: "http://jabber.org/protocol/url-data",
            target: uri,
          }),
        ),
        xml(
          "file",
          "urn:xmpp:file:metadata:0",
          xml("media-type", {}, file.type),
          xml("name", {}, file.name),
          xml("size", {}, file.size.toString()),
        ),
      ),
      xml(
        "reference",
        { xmlns: "urn:xmpp:reference:0", type: "data" },
        xml(
          "media-sharing",
          "urn:xmpp:sims:1",
          xml(
            "file",
            "urn:xmpp:jingle:apps:file-transfer:5",
            xml("media-type", {}, file.type),
            xml("name", {}, file.name),
            xml("size", {}, file.size.toString()),
          ),
          xml(
            "sources",
            {},
            xml("reference", {
              xmlns: "urn:xmpp:reference:0",
              type: "data",
              uri: uri,
            }),
          ),
        ),
      ),
      xml("store", "urn:xmpp:hints"),
      xml("markable", "urn:xmpp:chat-markers:0"),
      xml("x", "jabber:x:oob", xml("url", {}, uri)),
    )

    const bodyText = desc ? desc : uri
    message.append(xml("body", {}, bodyText))

    return message
  },
}

export default XmppSimsApi
