import React, { useState, useEffect } from "react"
import AdHocCommands from "../../api/adHocCommands"
import {
  Dialog,
  DialogBody,
  FormGroup,
  InputGroup,
  Button,
  Classes,
  DialogFooter,
} from "@blueprintjs/core"
import css from "./AddEditUserModal.module.css"
import XMPPError from "@xmpp/error"

interface AddEditUserModalProps {
  isOpen: boolean
  onClose: (event?: any) => void
  phoneNumber?: string
  firstName?: string
  lastName?: string
}

export const AddEditUserModal: React.FC<AddEditUserModalProps> = ({
  isOpen,
  onClose,
  ...rest
}) => {
  const [phoneNumber, setPhoneNumber] = useState(rest.phoneNumber ?? "")
  const [firstName, setFirstName] = useState(rest.firstName ?? "")
  const [lastName, setLastName] = useState(rest.lastName ?? "")

  useEffect(() => {
    setPhoneNumber(rest.phoneNumber ?? "")
    setFirstName(rest.firstName ?? "")
    setLastName(rest.lastName ?? "")
  }, [rest.phoneNumber, rest.firstName, rest.lastName])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      await AdHocCommands.addExternalContact({
        client: window.XMPP!,
        phoneNumber: phoneNumber,
        firstName: firstName,
        lastName: lastName,
      })
      onClose(e)
    } catch (e) {
      const error = (e as XMPPError).text
      if (error === "Invalid phone number") {
        ;(
          window.document.getElementById("phone") as HTMLInputElement | null
        )?.select()
      }

      window.appToaster.show({
        message: error,
        intent: "danger",
        timeout: 2000,
      })
    }
  }

  const isEdit = rest.phoneNumber !== undefined

  return (
    <Dialog
      canEscapeKeyClose
      canOutsideClickClose={false}
      isOpen={isOpen}
      onClose={onClose}
      title={isEdit ? "Edit external contact" : "Add new external contact"}
      className="bp4-dialog-container w-full md:max-w-3xl mx-auto"
    >
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="flex flex-col">
          <DialogBody className={css.addUserModalInput}>
            <div className="flex flex-col gap-6">
              <FormGroup label="Firstname" labelFor="firstname">
                <InputGroup
                  large
                  id="first_name"
                  fill={true}
                  value={firstName}
                  onValueChange={(value) => setFirstName(value)}
                />
              </FormGroup>

              <FormGroup label="Surname" labelFor="surname">
                <InputGroup
                  large
                  id="last_name"
                  fill={true}
                  value={lastName}
                  onValueChange={(value) => setLastName(value)}
                />
              </FormGroup>

              <FormGroup label="Phone number" labelFor="phone">
                <InputGroup
                  id="phone"
                  large
                  required
                  placeholder="+48"
                  fill={true}
                  value={phoneNumber}
                  onValueChange={(value) =>
                    setPhoneNumber(value.replace(/\s+/g, ""))
                  }
                />
              </FormGroup>
            </div>
          </DialogBody>
          <DialogFooter>
            <div className="flex justify-end gap-3">
              <Button
                text="Cancel"
                large
                intent="danger"
                onClick={onClose}
                className={Classes.MINIMAL}
              />
              <Button
                type="submit"
                large
                intent="primary"
                text={isEdit ? "Save" : "Add"}
              />
            </div>
          </DialogFooter>
        </div>
      </form>
    </Dialog>
  )
}
