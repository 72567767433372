import React from "react"
import type { OverlayRenderProps } from "react-photo-view/dist/types"
import { Tag } from "@blueprintjs/core"

export const ImageTopToolbar = ({
  rotate,
  onRotate,
  onScale,
  scale,
}: OverlayRenderProps) => {
  return (
    <div
      className="bottom-2 right-2 flex flex-row gap-2 bg-transparent"
      data-testid="image-top-toolbar"
    >
      <Tag
        icon="plus"
        round
        intent="primary"
        large
        onClick={() => onScale(scale + 0.5)}
      />
      <Tag
        icon="minus"
        round
        intent="primary"
        large
        onClick={() => onScale(scale - 0.5)}
      />
      <Tag
        icon="image-rotate-right"
        round
        intent="primary"
        large
        onClick={() => onRotate(rotate + 90)}
      />
    </div>
  )
}

export default React.memo(ImageTopToolbar)
