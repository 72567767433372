import { Icon, IconName } from "@blueprintjs/core"
import classNames from "classnames"
import React from "react"
import { MessageStatus } from "../../lib/messageHelpers"

import css from "./ReadStatus.module.css"

import cssUtils from "../utilsCss.module.css"

type ReadStatusDriverRoomProps = {
  status: MessageStatus
  disableTooltip?: boolean
  onRetry?: () => void
}

type IconWithTooltip = {
  icon: IconName
  tooltipText: string
  disableTooltip?: boolean
  className?: string
}

export const IconWithTooltip = ({
  icon,
  tooltipText,
  disableTooltip = false,
  className,
}: IconWithTooltip) => {
  return (
    <span
      className={classNames(cssUtils.tooltip, className, css.alignIconWithText)}
    >
      <Icon icon={icon} size={12} />
      <span
        className={classNames(cssUtils.tooltiptext, {
          [cssUtils.hidden]: disableTooltip,
        })}
      >
        {tooltipText}
      </span>
    </span>
  )
}

export const ReadStatusDriverRoom = ({
  status,
  onRetry,
  disableTooltip = false,
}: ReadStatusDriverRoomProps) => {
  return (
    <div
      className={classNames(
        status === "displayed" && css.displayedStatus,
        status === "not_sent" && css.failedStatus,
        ["sent", "scheduled", "received"].includes(status) && css.sentStatus,
      )}
    >
      {status === "scheduled" && (
        <IconWithTooltip
          icon="time"
          tooltipText="Scheduled for delivery"
          disableTooltip={disableTooltip}
        />
      )}
      {status === "not_sent" && (
        <>
          <IconWithTooltip
            icon="error"
            tooltipText="Failed to send"
            disableTooltip={disableTooltip}
          />
          {onRetry && (
            <a onClick={() => onRetry()} className={css.retryLink}>
              Send again
            </a>
          )}
        </>
      )}
      {status === "sent" && (
        <IconWithTooltip
          icon="tick"
          tooltipText="Sent to the driver"
          disableTooltip={disableTooltip}
        />
      )}
      {(status === "displayed" || status === "received") && (
        <IconWithTooltip
          icon="drive-time"
          tooltipText={
            status === "displayed" ? "Seen by driver" : "Received by driver"
          }
          disableTooltip={disableTooltip}
        />
      )}
    </div>
  )
}

type ReadStatusNormalRoomProps = {
  status: MessageStatus
}

export const ReadStatusNormalRoom = ({ status }: ReadStatusNormalRoomProps) => {
  return (
    <div
      className={status === "displayed" ? css.displayedStatus : css.sentStatus}
    >
      <Icon icon="tick" size={12} />
      {status !== "sent" && (
        <Icon icon="tick" className={css.shiftIcon} size={12} />
      )}
    </div>
  )
}

type ReadStatusProps = {
  status: MessageStatus
  withDriver?: boolean
  withExternalContact?: boolean
  disabledTooltip?: boolean
  onRetry?: () => void
}

export const ReadStatus = ({
  status,
  onRetry,
  withDriver = false,
  withExternalContact = false,
  disabledTooltip = false,
}: ReadStatusProps) => {
  return withDriver || withExternalContact ? (
    <ReadStatusDriverRoom
      status={status}
      disableTooltip={disabledTooltip}
      onRetry={onRetry}
    />
  ) : (
    <ReadStatusNormalRoom status={status} />
  )
}
